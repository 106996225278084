.answer_each{
    border-bottom: 1px solid ;
    /* outline: 1px solid; */
    background: #eff6fe;
    height: 32px;
    margin-bottom: 7px;
    width: 350px;
}
.container-setup{
    
}
.form-inner{
    position: absolute;
    left: 37%;
}
.image-value{
    margin-left: 65px;
    display: inline-block;
    margin-top: 15px;
    text-align: center;
}
#school-here{
    margin-left: 82px;
}
.image-itself{
    display: block;
    margin-bottom: 10px;
    cursor: pointer;

}
/* .image-itself:hover{
    background-color: #ff9933;
    border-radius: 50px;
    width: 65px;

} */
.image-views{
    width: 450px;
    margin-top: 40px;
    margin-left: 30%;
    display: inline-block;
    
}
.image-text{
    margin-left: 60px;
    margin-bottom: 10px;
}
#save{
    width: 350px;
    height: 50px;
    text-align: center;
    padding-top: 15px;
    border-radius: 20px;
    background-color: #ff9933;
    margin-top: 30px;
    cursor: pointer;
    box-shadow: 0 10px 30px 0px rgba(0, 39, 62, 0.5);
    -webkit-box-shadow: 0 0px 8px 0px rgba(90, 101, 107, 0.5);
    /* margin-left: 60px; */
}
.container-bottom{
    margin-top: 50px;
    
}
.setup-save-button{
    width: 94%;
    /* margin: 50px 25px; */
    cursor: pointer;
}
.setup-save-button-back{
    width: 150px;
    display: inline-flex;
    background: #fff;
    color: #000000;
    border: solid 2px rgba(0, 38, 62, 0.65);
}
.setup-save-button-back:hover{
    color: #fff;
}
.setup-save-button-submit{
    width: 150px;
    display: inline-flex;
    margin-left: 50px;
    margin-bottom: 10px;
}
.login100-image{
    margin-left: 60px;
}
.required-field{
    color:red;
    font-size: 11px;
}
.required-field-bottom{
    margin-left: 60px;
    margin-top: 14px;
}
#maptize-logo{
    width: 350px;
    height: 100px;
    margin-bottom: 60px;
    /* margin-left: 20px; */
}


.form-total{
    /* background-color: #ececec; */
}
#selector{
    width: 350px;
}
.Questions-each-container{
    /* margin-left: 5px; */
}
.Demographic{
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 20px;

}
.required-field-values{
    margin-top: 10px;
}
.Demographic-total{
    width: 350px;
}
.Demographic-agegroup{
    margin-bottom: 10px;
}
.Demographic-warning{
    margin-bottom: 10px;
    font-size: 13px;
    color: red;
}
.Demographic-education{
    margin-top: 20px;
    margin-bottom: 10px;
}
.Demographic-each{
    margin-top: 25px;
    margin-bottom: 10px;
}
.required-field-all{
    margin-bottom: 0px;
    margin-top: 30px;
    /* position: absolute; */
    
}
.Demographic-industry{
    width: 350px;
}
#search-heres{
    position: relative;
    border-bottom: 1px solid;
    background: #eff6fe;
    padding-left: 8px;
    font-size: 12px;
    width: 350px;
    height: 32px;
    /* margin-top: 20px; */
}
.search-results-setup{
    /* position: relative; */
    height: 54px;
    width: 350px;
    padding-bottom: 35px;
cursor: pointer;
}
.search-results-setup:hover{
    background-color: rgba(0,116,217,0.1);
    width: 345px !important;
  }
.search-results-total-setup{
    /* position: relative; */
    
    top: 55px;
    left: 0px;
    width: 350px;
    
    
    cursor: pointer;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.4);
    z-index: 8;
    background-color: #ffffff;
    
}

#search-leftside-icon{
    position: relative;
}
.search-results:hover{
    background: rgba(0,116,217,0.1);
    
    width: 337px;
}

#search-location{
    position: relative;
}
.error-logger{
    height: auto;
    padding-bottom: 5px;
    margin-bottom: 30px;
    background: #fff;
    text-align: center;
    padding-top: 5px;
    font-family: SourceSansPro;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.02;
    letter-spacing: 1.2px;
    color: red;
    border: 1px solid red;
    width: 100%;

}
.setup-total{
    background: #eff6fe;
    width: 100%;
    height: 110%;
    position:fixed;
}
.react-dropdown-select2{
    border: none !important;
    border-bottom: 1px solid !important;
}