#register{
    width: 100%;
    display: inline-block;
    
}
#register-button{
    background: #ff9933;
    /* box-shadow: rgb(255, 153, 51); */
    box-shadow: 0 10px 30px 0px rgba(255, 153, 51, 0.5);
}
#login{
    width: 47%;
    display: inline-block;
    margin-right: 20px;

}
.error-logger{
    width: 400px;
    height: 30px;
    background: #ff704d;
    text-align: center;
    padding-top: 5px;

}