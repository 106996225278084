

  
  
  
  
  /*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/
  @import url('https://fonts.googleapis.com/css?family=Roboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CSource+Sans+Pro%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&ver=5.4.1');

  * {
      margin: 0px; 
      padding: 0px; 
      box-sizing: border-box;
  }
  
  body, html {
      height: 100%;
      font-family: Poppins-Regular, sans-serif;
  }
  
  /*---------------------------------------------*/
  a {
      font-family: Poppins-Regular;
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  
  a:focus {
      outline: none !important;
  }
  
  a:hover {
      text-decoration: none;
    
  }
  
  /*---------------------------------------------*/
  h1,h2,h3,h4,h5,h6 {
      margin: 0px;
  }
  
  p {
      font-family: Poppins-Regular;
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px;
  }
  
  ul, li {
      margin: 0px;
      list-style-type: none;
  }
  
  
  /*---------------------------------------------*/
  input {
      outline: none;
      border: none;
  }
  
  textarea {
    outline: none;
    border: none;
  }
  
  /* textarea:focus, input:focus {
    border-color: transparent !important;
  } */
  
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
  
  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; }
  textarea:focus::-moz-placeholder { color:transparent; }
  textarea:focus:-ms-input-placeholder { color:transparent; }
  
  input::-webkit-input-placeholder { color: #adadad;}
  input:-moz-placeholder { color: #adadad;}
  input::-moz-placeholder { color: #adadad;}
  input:-ms-input-placeholder { color: #adadad;}
  
  textarea::-webkit-input-placeholder { color: #adadad;}
  textarea:-moz-placeholder { color: #adadad;}
  textarea::-moz-placeholder { color: #adadad;}
  textarea:-ms-input-placeholder { color: #adadad;}
  
  /*---------------------------------------------*/
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  .login-avatar-register{
    cursor: pointer;
  }
  iframe {
      border: none !important;
  }
  
  
  /*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
  .txt1 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #999999;
    line-height: 1.5;
        margin-right: 10px;
  }
  
  .txt2 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #00273e;
    line-height: 1.5;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ login ]*/
  
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #fff;  
  }
  
  .wrap-login100 {
    width: 390px;
    /* background: #fff; */
    margin-right: 40%;
  }
  
  
  /*------------------------------------------------------------------
  [ Form ]*/
  
  .login100-form {
    width: 100%;
  }
  
  .login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 39px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
  }
  
  .login100-form-avatar {
    display: block;
    width: 300px;
    height: 100px;
    /* border-radius: 50%; */
    overflow: hidden;
    
  }
  
  .login100-form-avatar img {
    width: 66%;
    
  }
  
  /*------------------------------------------------------------------
  [ Input ]*/
  
  .wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #d9d9d9;
  }
  
  .input100 {
    font-family: SourceSansPro;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: 18px;
  
    display: block;
    width: 100%;
    height: 52px;
    background: transparent;
    padding: 0 5px;
    margin-top: 10px;
  }
  #usern{
    font-family: SourceSansPro;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: 18px;
  }
  #passw{
    font-family: SourceSansPro;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    font-size: 18px;
  }
  /*---------------------------------------------*/ 
  .focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .focus-input-red::before{
    background: red !important;
  }
  .focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 0;
    height: 2px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    background: #00273e;
  }
  
  .focus-input100::after {
    font-family: Poppins-Medium;
    font-size: 18px;
    color: #000000;
    line-height: 1.2;
  
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0px;
    padding-left: 5px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .input100:focus + .focus-input100::after {
    top: -5px;
    font-size: 15px;
  }
  
  .input100:focus + .focus-input100::before {
    width: 100%;
  }
  
  .has-val.input100 + .focus-input100::after {
    top: -5px;
    font-size: 15px;
  }
  .has-val2.input100 + .focus-input100::after {
    top: 3px;
    font-size: 15px;
  }
  .has-val.input100 + .focus-input100::before {
    width: 100%;
  }
  
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .login-logo-login2{
    width: 478px;
    height: 61px;
    font-size: 44px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.02;
    letter-spacing: 1.2px;
    color: #00263e;
    margin-bottom: 25px;
  }
  .login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
  cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background-color: #396bf0;
    box-shadow: 0 2px 9px 5px rgba(7, 12, 87, 0.19);
    -moz-box-shadow: 0 2px 9px 5px rgba(7, 12, 87, 0.19);
    -webkit-box-shadow: 0 2px 9px 5px rgba(7, 12, 87, 0.19);
    -o-box-shadow: 0 2px 9px 5px rgba(7, 12, 87, 0.19);
    -ms-box-shadow: 0 2px 9px 5px rgba(7, 12, 87, 0.19);
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .login100-form-btn:hover  {
    background-color: #00263e;
    box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  }
  
  
  .login100-40-btn{
    width: 40%;
    display: inline-block;
    margin-left: 50px;
  }
  .login100-40-back{
    width: 40%;
    display: inline-block;
    background-color: #999999;
  }
  .login100-40-back:hover {
    background-color: #414141;
  }
  /*------------------------------------------------------------------
  [ Alert validate ]*/
  
  .validate-input {
    position: relative;
  }
  
  .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;
  
    font-family: Poppins-Regular;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
  
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
  }
  
  .alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;
  
    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
  }
  
  .alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
  }
  
  @media (max-width: 992px) {
    .alert-validate::before {
      visibility: visible;
      opacity: 1;
    }
  }
  
  
  /*//////////////////////////////////////////////////////////////////
  [ Login more ]*/
  .login-more li {
    position: relative;
    padding-left: 16px;
  }
  
  .login-more li::before {
    content: "";
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #cccccc;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
  }
  .login100-form-btn{
    margin-top: 40px;
    
  }
  .login-image{
    width: 40%;
    position: fixed;
    right: 0;
    height: 100%;
    background: #00263e;
    /* color: #fff; */
    background-image: url(https://maptize.com/wp-content/uploads/2020/07/pattern01.png);
  }
  .login-logo-login{
    width: 478px;
    height: 61px;
    font-size: 44px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.02;
    letter-spacing: 1.2px;
    color: #00263e;
    margin-bottom: 25px;
    margin-left: 100px;
  }
  .login-logo-register{
    width: 478px;
    
    font-family: SourceSansPro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.02;
    letter-spacing: 1.2px;
    color: #00263e;
    margin-bottom: 25px;
  }
  .login-logo-fremium-total{
    left: 580px;
    top: 30px;
  }
  .login-logo-fremium-image{
    width: 45px;
    cursor: pointer;
  }
  .login-logo-register-free-menu
  {
    margin-left: 25px;
    width: 150px;
    border-radius: 1px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 36%);
    border: solid 0.5px #396bf0;
    background-color: #ffffff;
    padding: 5px 0px;
    z-index: 4;
    position: absolute;
  }
  .register-captcha{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .login-forgotpassword{
    margin-top:20px;
  }
  .login-or{
    margin-top: 20px;
  }
  .login-register{
    border: solid 2px rgba(0, 38, 62, 0.65);
    color: rgba(0, 38, 62, 0.65);
    background: #fff;
    box-shadow: none;
  }
  .login-register:hover{
    background-color:#fff;
  }
  .container-loginregister{
    background: #eff6fe;
  }