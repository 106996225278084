#root{
  height: 100%;
  width: 100%;
}
#top-level{
  height: 100%;  
}
body {
  height: 100%;  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mb-5{
  margin-bottom: 5px;
} 
.mt-5{
  margin-top: 5px;
} 
.mb-10{
  margin-bottom: 10px !important;
} 
.mt-10{
  margin-top: 10px !important;
} 
.mb-20{
  margin-bottom: 20px !important;
} 
.mt-20{
  margin-top: 20px !important;
} 

.mt-100{
  margin-top: 100px;
}

.ml-10{
  margin-left: 10px;
}

.ms-10{
  margin: 0 10px;
}

.pr-20{
  padding-right: 20px;
}

.pt-20{
  padding-top: 20px;
}

.ps-10{
  padding: 0 10px;
}

.top-115{
  top: 115px !important;
}

.top-95{
  top: 95px !important;
}

.width-200px{
  width: 200px;
}

.w-225px{
  width: 225px;
}

.height-500{
  height: 500px;
}

.height-auto{
  height: auto !important;
}

.top-325{
  top:325px;
}

.top-400{
  top: 400px !important;
}

.top90px{
  top: 90px !important;
}

.relative{
  position: relative;
}

.absolute{
  position: absolute;
}

.font-main-text{
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
}

.traffic-stats-number{
  background: #ffffff;
  height: 90px;
  margin-left: 18px;
  /* margin-top: 15px; */
  width: 170px;
  display: inline-block;
  text-align: center;
  padding-top: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.pl-20{
  padding-left: 15px;
}

.pt-5{
  padding-top: 5px;
}

.text-color-blue{
  color: #396bf0
}

.map-streetview-exit{
  position: absolute;
  top: 10px;
  font-weight: bold;
  right: 12px;
  width: 40px;
  z-index: 2;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 25px;
  background-color: rgb(68, 68, 68);
  color: lightgray;
}

#pdf-export-container{
  position: absolute; 
  left: -10000px; 
  top: 0px;
}

.color-purple{
  box-shadow: 0 0 0 1px #5469d4, 0 2px 5px 0 rgba(42, 47, 69, 0.3), 0 4px 9px 0 rgba(42, 47, 69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background: #5469d4 !important;
}

.h-100{
  height:100% !important;
}

.h-300px{
  height: 300px;
}

.mh-100{
  min-height: 100px !important;
}

.w-60p{
  width: 60% !important;
}

.pt-15{
  padding-top: 15px !important;
}

.pb-10{
  padding-bottom: 10px;
}

.cursor-pointer{
  cursor: pointer;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.center-content-flex{
  display: flex; 
  justify-content: center;
}

.justify-content {
  display: flex;
  justify-content: center;
}

.button-purple{
  box-shadow: 0 0 0 1px #5469d4, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 2px;
    text-align: center;
    height: 20px;
    width: 80px;
    background: #5469d4;
    border: 12px;
    color: #fff;
}

.main-mapping-save{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-general{
  color: red;
}

.button-disabled{
  background-color: #b9b9b9 !important;
}

.font-normal{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

.font-bold{
  font-weight: bold;
}

.font-weight600{
  font-weight: 600;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.width-408px{
  width: 408px;
}

.color-orange{
  color: #FF9800 !important;
}

.color-dark-blue{
  color: #396bf0 !important;
}

.mt-10{
  margin-top: 10px;
}

.margin-x-10{
  margin: 0 10px !important;
}

.width-100{
  width: 100% !important;
}

.w-70{
  width: 70px;
}

.w-240{
  width: 240px !important;
}

.ml-0{
  margin-left: 0px !important;
}

.pt-3{
  padding-top: 3px;
}

.pl-15{
  padding-left: 15px !important;
}

.pb-25{
  padding-bottom: 25px !important;
}

.inline-block{
  display: inline-block;
}

.fw-normal{
  font-weight: normal !important;
}

.cursor-default{
  cursor: default !important;
}

.list-expand-icon{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 10px;
  background-color: #eff6fe;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  position: relative;
  z-index: 4;
}

.filter-map-locations{
  display: block;
  position: relative;
  margin: 0 20px;
  height: 40px;
}

.filter-map-locations-text{
  text-align: right;
  margin-right: 55px;
  padding-top: 8px;
  background: #fff;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #000000;
}


#map-container {
  position: relative;
  height: 180px;
  width: 1200px;
}

#map {
  position: relative;
  
  width: 100%;
  z-index: 0;
}
#container{

  
  z-index: 0;
}
#state{
  width: 45%;
  display: inline-block;
  margin-right: 39px;
}
#zip{
  width: 45%;
  display: inline-block;
  margin-bottom: 30px;
}
.prospect-address{
  margin-top: 2px;
  display: block;
}
#droppingcheck-showmore{
  display: inline-block;
  cursor: pointer;
  color:#00263E;
  font-weight: bold;
}
#droppingcheck-d{
  display: inline-block;
    cursor: pointer;
    margin-top: 15px;
    color: red;

}
.sidebar-closing{
  margin-left: 345px;
  margin-top: 13px;
  font-weight: 800;
  cursor: pointer;
  background: #fff;
    width: 35px;
    height: 35px;
    text-align: center;
    padding-top: 9px;
    border-radius: 20px;
    border: solid .5px;
}
.prospect-background{
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.prospect-search-total{
  z-index: 7;
  width: 100%;
  height: 100%;
  position: absolute;
}
.prospect-save{
  height: 30px;
  margin-left: 10px;
  width: 85px;
  position: absolute;
  top: 17px;
  text-align: center;
  background: #FF9800;
  padding-top: 5px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
}
.sidebar-right{
display: block;
width: 400px;
height:100%;
right: 0px;
position: absolute;
z-index: 2;
background-color: rgba(255,255,255, .95);

box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.3);

}
.prospect-searchbar{
  font-size: 14px;
  padding-left: 10px;
  position: absolute;
  width: 380px;
  height: 30px;
  box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.7);
  border-radius: 8px;
}
.prospect-searchbotton{
  width: 70px;
  color: #fff;
  
  text-align: center;
  cursor: pointer;
  height: 30px;
  background: #00263E;
  position: absolute;
  margin-left: 365px;
}
.prospect-searchbar-total{
  left: 37%;
  top: 34%;
  position: absolute;
}
.sidebar-content{
  margin-top: 20px;
    font-size: 16px;
    border-radius: 10px;
    font-family: sans-serif;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #ffffff;
}
.sidebar-eachrow{
  padding-top: 10px;
  padding-left: 20px;
  z-index: 3;
}
.sidebar-image{
  width: 300px;
  height: 250px;

     margin-left: 50px;
    margin-top: 20px;
}
.leaflet-tooltip{
  background: transparent !important;
  font-weight: 800;
  border: none;

}
.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00263E;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  
}

.marker-pin::before {
    content: '';
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #9e9edc;
    position: absolute;
    border-radius: 50%;
 }
 .marker-pin-side {
  width: 15px;
  height: 15px;
  border-radius: 50% 50% 50% 0;
  background: #fff;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  
}
.marker-pin-prospect{
  
}

.marker-pin-side::after {
  content: '';
  width: 8px;
  height: 8px;
  margin: 4px 0 0 3px;
  background: #eff6fe;
  position: absolute;
  border-radius: 50%;
 }
 .custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}
.padding-left5{
  padding-left: 10px;
}
#search-here{
  position: absolute;
  z-index: 3;
  right: 40%;
  background: white;
  width: 170px;
  text-align: center;
  top: 30px;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.4);
  border-radius: 21px;
  cursor: pointer;
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 15px;
  padding-left: 5px;
  /* display: none; */
}
#search-leftside{
  top: 20px;
  
  width: 380px;
  height: 40px;
  font-family: "Arial Narrow", Arial, sans-serif; font-size: 18px;   position: absolute;
  text-align: left;
  z-index: 3;
  box-shadow: -2px 0 18px -3px rgba(0,0,0,0.2);
  border-radius: 8px;
  padding-left: 55px;
  border-top-color: transparent;
    border-left-color: transparent;
  left: 3px;

}
#search-centerside{
  
  
  width: 255px;
  height: 40px;
  font-family: "Arial Narrow", Arial, sans-serif; font-size: 18px;  
  text-align: left;
  z-index: 3;
  box-shadow: -2px 0 18px -3px rgba(0,0,0,0.2);
  border-radius: 8px;
  padding-left: 55px;
  border-top-color: transparent;
  border-left-color: transparent;
  margin-top: 20px;

}
#search-leftside-icon{
  z-index: 3;
  position: absolute !important;
  left: 350px;
  margin-top: 34px;
  cursor: pointer;

}
#search-leftside-loader{
position: absolute;
    top: 6px;
    left: 25px;
}
.building-mapbox{
  margin-top: -11px;
  margin-left: -11px;
  width: 22px;
  height: 22px;
  position: absolute;
  border-radius: 50%;
  z-index: 5;
}

.budiling{
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 11px;
  margin-left: 5px;
  border-radius: 50%;
  z-index: 5;
}
.business-div{
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 11px;
  margin-left: 5px;
  background-color: #00263E;
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  border-radius: 50%;
  z-index: 5;
}
.business-div-sidebar{
  width: 100%;
  height: 100%;
  border-radius: 50px;
  font-size: 25px;
  text-align: center;
  padding-top: 35%;
  background-color: #00263E;
  color: #fff;
  font-weight: bold;
}
.sidebar-total{
  /* overflow-y: scroll; */
  right: 0px;
  width: 453px;
  height: 100%;
  position: fixed;
  transform: translateX(0px);
  transition: transform 0.3s;

  /* transition: transform 0.3s; */
  z-index: 3;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.4);
  background: #eff6fe;
  
}
#Status-total{
  position: absolute;
  width: 159px;
  height: 40px;
  top: 40px;
  left: 30px;
}
.sidebar-total-hide{
  transform: translateX(453px);
  transition: transform 0.3s;

  
}
#sidebar-scroll{
  overflow-y: scroll;
  position: relative;
  height: 100%;
  width: 100%;
}

#sidebar-top{

}
#open-settings{
  width: 30px;
  top: 8px;
  left: -6px;
  height: 25px;
  position: absolute;
  z-index: 5;
  cursor: pointer;

}
#open-settings-total{
  border-right: 1px solid black;
  position: absolute;
  z-index: 4;
  height: 42px;
  width: 30px;
  top: 22px;
  left: 15px;

}
.search-here-value{
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  margin: 4.5px 0px;
}
/* Spinner center marker here */
.spinner-center {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}
.spinner-setter{
  position: absolute;
    z-index: 3;
    left: 50%;
    top: 30%;
}
.spinner-center > div {
  width: 23px;
  height: 23px;
  background-color: #00263E;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-center .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-center .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}



@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
.sk-fading-circle {
  width: 20px;
  height: 20px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}
/* .spinner {
  margin: -10px;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #ffffff;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
    background: black;
  }
} */

/* .loader-main,
.loader-main:before,
.loader-main:after {
  border-radius: 50%;
}
.loader-main {
  color: #000000;
  font-size: 3px;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader-main:before,
.loader-main:after {
  position: absolute;
  content: '';
}
.loader-main:before {
  width: 5.2em;
  height: 10.2em;
  background: #ffffff;
  border-radius: 10.2em 0 0 10.2em;
  top: -1.1em;
  left: -1.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader-main:after {
  width: 5.2em;
  height: 10.2em;
  background: #ffffff;
  border-radius: 0 10.2em 10.2em 0;
  top: -1.1em;
  left: 4em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */


.category_selector{
  width: 800px;
  height: 500px;
  background-color: #ffffff;
  position: absolute;
  z-index: 5;
  right: 24%;
  top: 100px;
}
.category_selector_be{
width: 100%;
height: 100%;
position: absolute;
background: rgba(0, 0, 0, 0.5);
z-index: 4;

}
.Questions{
  margin-left: 12%;
  margin-top: 7%;
}
.Questions_each{

  padding-bottom: 12px;
}
.answer_each{
  width: 300px;
  height: 20px;
  margin-bottom: 20px;
  display: inline-block;
}
.Sending{
  width: 100px;
  height: 30px;
  display: inline-block;
  margin-left: 150px;
    margin-top: 20px;
}
#add_each{
  display: inline-block;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  top: 163px;
  right: 374px;
  cursor: pointer;
}
.add_sub_each{
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
  margin-left: 12px;

}
#login_temp_background{
  position: absolute;
  width: 1500px;
  height: 650px;
  top:-100px;
  right: -358px;
  background-color: #ffffff;
  z-index: 9;
}
#username_temp{
width: 200px;
height: 20px;
position: absolute;
top:200px;
left: 500px;
}
#username_temp_text{
  width: 200px;
height: 20px;
position: absolute;
top:180px;
left: 500px;
}
#button_temp{
  position: relative;
  top: 230px;
  left: 500px;
  height: 20px;
  width: 50px;
}

#selector{
  width:300px
}
.search-results{
width: 300px;
padding-left: 12px;
padding-bottom: 10px;
cursor: pointer;
}
.search-results:hover{
  background-color: rgba(0,116,217,0.1);
  width: 100% !important;
  
}
#droppingcheck-getrange{
  position: relative;
  display: inline-block;
  margin-right: 25px;
  margin-left: 25px;
  cursor: pointer;
  font-weight: bold;
  color: #FF9800;
}
.image-val{
  width: 270px;
}
.search-results-total{
  
  border-radius: 7px;
  padding-left: 10px;
  z-index: 8;
  background-color: #ffffff;
  height: 40px;
  width: 364px !important;
}
.search-categories{
  color: black;
  font-size: 10px;
  position: relative;
}

/* new stuff here  for login */

.barchart-horizontal{
  background-color: #fff;
  width: 404px;
}
body{
  width: 100% !important;
  height: 100% !important;
}
* {
  font-family: "Source Sans Pro", Sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container-search{
  margin-right:-72px !important;
  margin-top:1% !important;
      position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 100px;
}
.container-layers{
  position: absolute;
  display: inline-block;
  top: 0px;
  width: 40px;
  height: 40px;
  background: #eff6fe;
  border-radius: 1px;
  z-index: 3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  cursor: pointer;
}
.searchbarbuttons-total{
  position: absolute;
  top: 3px;
  right: 38px;
}
#status-search-total{
  position: absolute;
  z-index: 5;
  /* margin-top: 5px; */
  left: 60px;
}
#status-search-total-comp{
  
  z-index: 3;
  margin-top: 5px;
  left: 60px;
}
.searchbarbuttons-total-comp{
  position: absolute;
  bottom: 15px;
  right: 38px;
}
.mapboxgl-ctrl-group button {
  width: 40px!important;
  height: 40px!important;
  background-color: #eff6fe!important;

}
.mapboxgl-ctrl-traffic {

  /* top: -60px;
  left: 128px;
  position: absolute;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4); */
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 56px;
  margin-top: -52px;
}
/* .mapboxgl-ctrl-map{
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23333333%22%20viewBox%3D%22-10%20-10%2060%2060%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%3E%3Cg%3E%3Cpath%20d%3D%22m25%2031.640000000000004v-19.766666666666673l-10-3.511666666666663v19.766666666666666z%20m9.140000000000008-26.640000000000004q0.8599999999999923%200%200.8599999999999923%200.8600000000000003v25.156666666666666q0%200.625-0.625%200.783333333333335l-9.375%203.1999999999999993-10-3.5133333333333354-8.906666666666668%203.4383333333333326-0.2333333333333334%200.07833333333333314q-0.8616666666666664%200-0.8616666666666664-0.8599999999999994v-25.156666666666663q0-0.625%200.6233333333333331-0.7833333333333332l9.378333333333334-3.198333333333334%2010%203.5133333333333336%208.905000000000001-3.4383333333333344z%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E);
  background-image: url("Traffic_on.png");

}
.mapboxgl-ctrl-traffic {
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23333333%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%22-2%20-2%2022%2022%22%3E%0D%0A%3Cpath%20d%3D%22M13%2C4.1L12%2C3H6L5%2C4.1l-2%2C9.8L4%2C15h10l1-1.1L13%2C4.1z%20M10%2C13H8v-3h2V13z%20M10%2C8H8V5h2V8z%22%2F%3E%0D%0A%3C%2Fsvg%3E);
  background-image: url("Traffic_off.png");
} */
.mapboxgl-ctrl-map{
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 56px;
  margin-top: -52px;
}
.mapboxgl-ctrl-top-left {
  top: 90px !important;
  left: 100px !important;
}
.container-layers-dots{
  font-size: 30px;
  color: #396bf0;
}
.container-layers-dots2{
  width: 6px;
  background: black;
  height: 6px;
  border-radius: 4px;
  margin-bottom: 4px;
}
.container-layers-settings{
  position: absolute;
  width: 200px;
  top: 40px;
  background: #eff6fe;
}
.container-layers-square{
  width: 30px;
  height: 25px;
  margin-left: 16px;
  margin-top: 6px;
}
#container-layers-box{
  width: 250px;
  position: absolute;
  
  overflow: hidden;
  right: 63px;
  top: 16px;
  border-radius: 20px;
  background-color: rgba(0,38,62, 0.8);
  color: #fff;
}
.container-layers-eachval{
  padding-left: 25px;
  padding-top: 3px;
  height: 25px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.container-layers-eachval2{
  padding-left: 5px;
  padding-top: 3px;
  height: 25px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.container-layers-imagemain{
  width: 14px;
    height: 12px;
    display: inline-block;
}
.container-layer-text{
  display: inline-block;
  position: absolute;
  margin-top: -2px;
  margin-left: 4px;
}
.container-layers-eachval2:hover{
  background: #c5defa;
}
.container-layers-eachval:hover{
  background: #c5defa;
}
#container-layers-settings-side{
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  width: 145px;
  position: absolute;
  
  background: #eff6fe;
  left: 145px;
    top: 25px;
}
#container-layers-settings{
  
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  width: 145px;
  position: absolute;
  top: 45px;
  background: #eff6fe;
  
}
.container-layer-open{
  height: 45px;
  z-index: 5;
}
.prospect-name{
  display: block;
}
.prospect-each{
  margin: 10px 0;
}

.prospect-tasks-input{
  width: 90%;
    height: 20px;
    border: solid .5px;
}
.prospect-description-input{
  width: 90%;
  height: 200px;
  border: solid .5px;
}
.prospect-name-input{
  width: 90%;
  height: 20px;
  border: solid .5px;
}
.prospect-type{
  text-align: center;
  margin-top: 20px;
}
.prospect-form{
  margin: 50px 10px;
}
#container-layers-box-prospect{
  
  width: 250px;
  position: absolute;
  overflow: hidden;
  
  cursor: default;
  
  border-radius: 20px;
  background-color: rgba(0,38,62, 0.8);
  color: #fff;
  z-index: 2;
    top: 211px;
    right: 106px;
}
.click-scrollable{
  cursor: url('./Images/mark.png'), auto !important;
}
.droppingcheck{
  display: block;
  margin-bottom: 5px;
}
#droppingcheck-confirm{
  display: inline-block;
  color: green;
  cursor: pointer;
  margin-right: 15px;
}
#droppingcheck-delete{
  display: inline-block;
  color: red;
  cursor: pointer;
}
#container-layers-box-arrow{
  right: -4px;
  top: -9px;
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid rgba(0,38,62, 0.8);
}
.container {
  margin-top: 1% !important;
  z-index: 1;
}
.container .search {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: #eff6fe;
  border-radius: 1px;
  transition: all 1s;
  z-index: 4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
}
.container .search:hover {
  cursor: pointer;
}
.container .search::before {
  content: "";
  position: absolute;
  margin: auto;
  top: 22px;
  right: 0;
  bottom: 0;
  left: 20px;
  width: 11px;
  height: 2px;
  background: #396bf0;
  transform: rotate(45deg);
  transition: all 0.5s;
}
.container .search::after {
  content: "";
  position: absolute;
  margin: auto;
  top: -5px;
  right: 0;
  bottom: 0;
  left: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #396bf0;
  transition: all 0.5s;
}
.container2 .search2::before {
  content: "";
  position: absolute;
  margin: auto;
  top: 22px;
  right: 0;
  bottom: 0;
  left: 20px;
  width: 11px;
  height: 2px;
  background: black;
  transform: rotate(45deg);
  transition: all 0.5s;
}
.container2{
  position: absolute;
  right: 65px;
  height: 40px;
  /* box-shadow: 0 0 0 1px #396bf0, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08); */
  border-radius: 3px;
  cursor: pointer;
  margin-top: 3px;
}
.container2 .search2::after {
  content: "";
  position: absolute;
  margin: auto;
  top: -5px;
  right: 0;
  bottom: 0;
  left: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ;
  transition: all 0.5s;
}
.search-bar-search{
  width: 35px;
  height: 30px;
  position: absolute;
  z-index: 8;
  margin-top: -26px;
  margin-left: 4px;
}
.search-exit{
  width: 16px;
    top: 2px;
    position: absolute;
    left: 26px;
    cursor: pointer;
}
.search-bar-search .search::before {
  content: "";
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 17px;
  width: 11px;
  height: 2px;
  background: #396bf0;
  transform: rotate(45deg);
  transition: all 0.5s;
}
.search-bar-line{
  height: 20px;
  border-right: solid 2px #cdcdcd;
  margin-top: 2px;
}
.search-bar-search .search::after {
  content: "";
  position: absolute;
  margin: auto;
  top: -5px;
  right: 0;
  bottom: 0;
  left: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #396bf0;
  transition: all 0.5s;
}

.container-layers-square-open {
  position: absolute;
  top: -8px;
  right: -10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 4;
}

.container-layers-square-open::before{
  content: "";
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  width: 30px;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #396bf0;
  transform: rotate(45deg);
  
}
.container-layers-square-open::after{
  top: 28px;
  left: 15px;
  width: 30px;
  height: 2px;
  border: none;
  background: #396bf0;
  border-radius: 0%;
  transform: rotate(-45deg);
  content: "";
    position: absolute;
    margin: auto;
}
.container input {
  font-family: "Inconsolata", monospace;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  background: #ffffff;
  color: #00263E;
  text-shadow: 0 0 10px #ffffff;
  padding: 0 80px 0 20px;
  border-radius: 30px;
  transition: all 1s;
  opacity: 0;
  z-index: 5;
  font-weight: bolder;
  letter-spacing: 0.1em;
}
.container input:hover {
  cursor: pointer;
}
/* .container input:focus {
  width: 270px;
  left: -250px;
  opacity: 1;
  cursor: text;
}
.container input:focus ~ .search {
  
  background: #00263E;
  z-index: 6;
}
.container input:focus ~ .search::before {
  top: 0;
  left: 0;
  width: 25px;
}
.container input:focus ~ .search::after {
  top: 0;
  left: 0;
  width: 25px;
  height: 2px;
  border: none;
  background: white;
  border-radius: 0%;
  transform: rotate(-45deg);
} */
.container input::placeholder {
  color: #00263E;
  opacity: 0.5;
  font-weight: bolder;
}

.prospecting-2{
  width: 30px;
  height: 20px;
}
.box-container{
  width: 204px;
  border-radius: 2px;
  background-color: #ffffff;
  height: 120px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}
.box-container2{
  width: 204px;
  border-radius: 2px;
  background-color: #ffffff;
  height: 120px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
}
.boxdata-header-one{
  width: 200px !important;
    
}
.boxdata-container{
  display: inline-block;
  border-right: 4px solid #eff6fe;
}
.boxdata-header{
  
  height: 24px;
  padding-top: 4px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #000000;
  width: 404px;
  background: #ffffff;
  margin-top: 10px;
  padding-left: 10px;
  text-align: left;
}
.box-status{
  height: 27px;
  text-align: left;
  padding-top: 2px;
  border-bottom: 1px solid #e6e6e6;
  padding-left: 5px;
  border-top: solid 2px #f2f2f2;
}
.starredvalues{
  width: 15px;
  position: absolute;
  margin-top: -18px;
  margin-left: 80px;
  cursor: pointer;
}
.box-industry-piechart{
  position: relative !important;
  margin-left: 80px !important;
  width: 200px !important;
}
.starredvalues-long{
  margin-left: 81%;
}
.box-container-interestseach2 {
  font-size: 12px;
  text-align: right;
  padding-right: 15px;
  margin-left: auto;
  margin-right: 0;
  color: #8d8d8d;
}
.legend-values-total{
  position: absolute;
  bottom: 5px;
  max-height: 250px;
  min-width: 235px;
  left: 30px;
  z-index: 3;
  background: #eff6fe;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
}
.legend-values-each{
  display: inline-block;
  margin-left: 4px;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  border: solid 2px #fff;
  
}
.legend-values-total-legend{
  text-align: center;
  margin-left: 30px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(57, 107, 240,0.5);
    width: 125px;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
}
.legend-eachfilter{
  display: inline-block;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    width: 35px;
    text-align: center;
    letter-spacing: normal;
    /* position: absolute; */
    margin-right: 3px;
    margin-left: 9px;
}
.topbar-total-each{
  width: 86px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  height: 50px;
  border-bottom: solid 2px #d9d9d9;
  margin-bottom: 10px;
  color: #8d8d8d;
    cursor: pointer;
    display: inline-block;
}

.Competitor-social-image{
  width: 30px;
}
.Competitor-social{
margin-right: 10px;
}
.Competitor-social-total{
  display: inline-block;
  /* top: -4px; */
  margin: 4px 0 0 20px;
  position: absolute;
  width: 200px;
}
.topbar-total-each-selected{
border-bottom:  solid 2px #396bf0;
  color: #000000;
}
/* .demographic-info{
  text-align: center;
  width: 100%;
  border: solid 2.5px #f2f2f2;
  border-top:solid 2.5px #00263E;
  background: #ffffff;
  height: 40px;
  padding-top: 12px;
  padding-bottom: 37px;
} */
.container-prospect{
  top: 200px;
}
.box-container-value{
  position: relative;
  /* text-align: right; */
  /* padding-left: 30px; */
  margin-left: 27px;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 5px;
  /* padding-left: 10px; */
  font-family: "Source Sans Pro", Sans-serif;
  /* font-size: 12px; */
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  width: 140px;
}
.marker-pin-inside{

}
.marker-pin-inside-grey{
  color: grey;
}
.marker-pin-outer{
  background: #396bf0;
  position: absolute;
  left: 20px;
  top: 16px;

}
/* canvas{
  cursor: -webkit-grab !important; 
  cursor: grab !important;
}

canvas:active{
  cursor: -webkit-grabbing !important; 
  cursor: grabbing !important;
} */
.occupation-dist{
  
}
.leaflet-interactive{
  cursor: grab;
  pointer-events: none;
}
.leaflet-marker-icon{
  cursor: pointer;
}
.box-industry-type{
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  width: 85px;
  position: absolute;
  text-align: left;
}
.box-industry-type-prev{
  margin-top: 40px;
  color: #8d8d8d;
  border-bottom: 1px solid rgb(57, 107, 240,0.5);
  width: 159px;
  margin-left: 10px;
  padding-left: 0px;
  padding-bottom: 12px;
}
.box-industry-type-pie{
  font-size: 16px;
  border-bottom: 1px solid #f2f2f2;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 10px;
}
.box-price-total{
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}
.box-price{
  font-size: 14px;
  /* display: inline-block; */
}
.box-pop-current{
  font-size: 15px;
  /* padding-bottom: 8px; */
  /* border-bottom: .5px solid lightgrey; */
  width: 100px;
  /* margin-bottom: 8px; */
  position: absolute;
  /* top: 20px; */
  /* margin-bottom: 14px; */
  margin-top: 20px;
  color:#396bf0;
}
.box-price-bottom{
font-size: 13px;  
margin-top: 0px;
margin-left: 56px;
font-weight: normal;
width: 63px;
}
.box-price-bottom-diff{
  margin-left: -75px;
  font-weight: normal;
  position: absolute;
  margin-top: 90px;
  
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.box-price-up{
  position: absolute;
  margin-top: -25px;
  margin-left: 60px;
  font-weight: bold;
  font-size: 20px;
}
.box-price-line{
  margin-top: -13px;
  font-size: 11px;
  position: absolute;
  margin-left: 65px;
}
.demographic-sidebar{
  width: 100%;
  height: 200px;
  background: lightgray;
}
.box-building-size{
  font-size: 13px;
}
.box-star{
  width: 16px;
  position: absolute;
  margin-left: 70px;
}
.box-line-bottom{
  border-bottom: 1px solid #f2f2f2;
  width: 90px;
  margin-left: 50px;
  margin-bottom: 5px;
}
#sidebar-total-desc{
  text-align: center;
  width:100%;
  
  padding-top: 12px;
  
}

#sidebar-realestate{
height: 60px;
padding-top: 12px;
border-top: #00263E solid 2.5px;
text-align: center;

}
.sidebar-realestate-total{
  
  margin: 20px;
  width: 405px;
}
.search-here-red{
  color: #FF4500;
}
.box-showmore{
  font-weight: bold;
  cursor: pointer;
  width: 100%;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
.dropping-prospectval{
  margin-left: 5px;
  margin-right: 5px;
}

.legend-task-manager{
  position: absolute;
  left: 315px;
  bottom: 16px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  color: #ffffff;
  text-align: center;
  padding: 14px 10px 0px 10px;
  cursor: pointer;
  height: 44px;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  background-color: #396bf0;
  
}
#box-export2:hover + .box-calculateview{
  display: block;
}
.box-calculateview{
  display: none;

}
#box-export {
  width: 130px;
  height: 25px;
  margin-left: 120px;
  text-align: center;
  background: #00263E;
  padding-top: 2px;
  border-radius: 5px;
  color: #fff;
  display: none;
}
#box-export2{
  width: 130px;
  height: 25px;
  margin-left: 120px;
  text-align: center;
  background: grey;
  padding-top: 2px;
  cursor: default;
  border-radius: 5px;
  color: #fff;
  display: none;
}
#box-goback{
  width: 100px;
  height: 25px;
  margin-left: 2px;
  
  text-align: center;
  background: #00263E;
  padding-top: 2px;
  border-radius: 12px;
  color: #fff;
}
.sidebar-total-tabs{
  width: 100%;
  height: 50px;
  margin-left: -12px;
}
.sidebar-total-each{
  width: 85px;
  height: 28px;
  padding-top: 2px;
  background-color: lightgray;
  margin-bottom: 11px;
  display: inline-block;
  margin-left: 25px;
  margin-top: 12px;
  border-radius: 20px;
  cursor: pointer;
}
.sidebar-total-each-selected{
  background-color: #00263E;
  color: #fff;
}
#sidebar-total-questionmark{
  margin-left: 8px;
  margin-top: 1px;
  overflow-x: visible;
  color: #00263E;
  position: absolute;
  cursor: pointer;
}

#sidebar-total-desc-small{
  font-size: 13px;
  z-index: 4;
  left: 80px;
  text-align: center;
  /* top: 1px; */
  padding: 5px;
  display: none;
  position: absolute;
  background-color: #00263E;
  color: #fff;
  width: 200px;
  border-radius: 30px;
}

.question_each_sub{
  font-size: 13px;
  color: gray;
  display: inline-block;
}

.container-clicked{
background: #037aff;
}
.container-maplayer-box{
  width: 14px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 30px;
  z-index: 5;
  

}
.container-layers-prospect{
  color: #fff;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 25px;
  
}
.container-layers-name{
  
  text-align: center;
  padding-bottom: 12px;
  padding-top: 12px;
  cursor: pointer;
}
.container-layers-image{
  width: 14px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 30px;
  z-index: 5;

}
.container-layers-image2{
  width: 14px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 80px;
  z-index: 5;
}
.container-layers-image4{
  width: 14px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 55px;
  z-index: 5;
}
.container-layers-image5 {
  width: 14px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 55px;
  z-index: 5;
}
.container-layers-image3{
  width: 14px;
  height: 12px;
  position: absolute;
  left: 6px;
  top: 5px;
  z-index: 5;
}
.container-layers-name:hover{
  background: #c5defa;
}
.search-results-values{
  position: absolute;
    width: 364px;
    z-index: 6;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    margin-top: 2px;
    top: 110px;
    left: 60px;
}
.search-results-values-comp{
  left: auto;
  top: auto;
}
.Psychograph-header{
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
}
.box-container-values3{
  padding: 10px 0;
}
.box-container-values4{
  padding: 2px 0;
}
.Psychographic-total{
  width: 80%;
  padding:10px;
  right: 10%;
  position: absolute;
  top: 10%;
  background: #fff;
  height: 80%;
}
.overflow-scroll{
  overflow: scroll;
}

.full-height-width{
  height: 100%;
  width: 100%;
}

#container-layers-first{
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
#container-layers-first:hover{
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.settings-legend{
  z-index: 1;
  width: 300px;
  background: #eff6fe;
  top: 90px;
  right: 50px;
  position: fixed;
  transform: translateX(0px);
    transition: transform 0.3s;
    padding: 0 7px 10px 7px;
}

.width-192{
  width: 192px;
}

.width-180{
  width: 180px !important;
}

.top-10{
  top: 10px;
}

.settings-legend-hide{
  transform: translateX(390px);
  transition: transform 0.3s;
}

.legend-totalval{
  z-index: 1;
  background: #eff6fe;
  top: 220px;
  left: 105px;
  position: absolute;
  transform: translateX(0px);
    transition: transform 0.3s;
    padding: 0 7px 10px 7px;
}
.legend-totalval2{
  z-index: 1;
  background: #eff6fe;
  top: 207px;
  left: 90px;
  position: absolute;
  transform: translateX(0px);
  transition: transform 0.3s;
  padding: 0 7px 10px 7px;
}
.Comp-totalval{
  z-index: 1;
  min-width: 200px;
  background: #eff6fe;
  top: 70px;
  left: 75px;
  position: absolute;
  transform: translateX(0px);
    transition: transform 0.3s;
    padding: 0 7px 10px 7px;
}
.checkmark {
  position: absolute;
  left: 12px;
  height: 16px;
  width: 16px;
  background-color: #dadada;
}
.Comp-nearall:hover input ~ .checkmark {
  background-color: #ccc;
}
.Comp-nearall input:checked ~ .checkmark {
  background-color: #158cbf;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.Comp-nearall .checkmark:after {
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.Comp-nearall input:checked ~ .checkmark:after {
  display: block;
}
.Comp-nearall{
  margin: 8px 0 18px 30px;
  font-size: 15px;
  color: #a7a7a7;
  cursor: pointer;
}
.Comp-nearall input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Comp-totalval-hide{
  transform: translateX(-490px);
  transition: transform 0.3s;
}
.legend-totalval-hide{
  transform: translateX(-390px);
  transition: transform 0.3s;
}
.legend-main{
  z-index: 3;
  background: #eff6fe;
  width: 270px;
  height: 45px;
}
.legend-tile-layers{
  position: absolute;
  z-index: 3;
  
  bottom: 0px;
  right: 0px;
  margin-bottom: 15px;
  width: 94px;
  background: #ffffff;
  text-align: left;
  margin-right: 209px;
  font-size: 14px;
  padding-left: 10px;
}

.legend-upper{
  font-size: 16px;
}
.legend-colors{
  width: 100%;
}
.legend-each{
  width: 12px;
  height: 25px;
  display: inline-block;
}

.info-value-box{
  border: 1px solid lightgray;
  width: 16px;
  height: 17px;
  color: lightgray;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  border-radius: 15px;
  margin-left: 167px;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}
.info-value-box-dif{
  color: white;
  border: none;
  margin-left: 11px !important;
  width: 64px;
  margin-top: 20px;
  z-index: 7;
}
.info-value-desc{
  font-weight: bold;
    font-size: 13px;
    text-align: left;
    
    margin-top: 7px;
}
.info-value-val{
  font-weight: normal;
  font-size: 13px;
}
.info-value-boxtotal{
  width: 250px;
  z-index: 5;
  position: absolute;
  background: #fafafa;
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  left: 85px;
  margin-top: 26px;
}
#legend-text{
  font-size: 11px;
  font-weight: bold;
  top: -13px;
  cursor: pointer;
  text-align: center;
  background: #fff;
}
.legend-opener-each {
  display: inline-block;
  width: 90px;
  padding-top: 4px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  height: 45px;
  color: #8d8d8d;
  background: #eeeeee;
  cursor: pointer;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  border: solid 3px #eeeeee;
}
.legend-opener-eachclick{
  border: solid 3px #396bf0;
  background: #eff6fe;
  color: #000000;
}

.zipcode-labels{
  font-weight: bold;
  font-size: 14px;
  cursor: grab;
}
.sidebar-open{
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid #D4D4D4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 3;
  background: #fff;
  right: 453px;
  top: 40%;
  font-size: 24px;
  padding-top: 10px;
  padding-left: 7px;
  
}
.sidebar-open-desc{
  width: 115px;
  display: none;
  position: absolute;
  font-size: 12px;
  padding-top: 7px;
  padding-left: 13px;
    color: #ffff;
  background: #00263E;
  cursor: default;
  height: 30px;
    top: -6px;
    left: 23px;
    border-radius: 12px;
}
.sidebar-open:hover .sidebar-open-desc{
  display: block;
}
.sidebar-closed{
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid #D4D4D4;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 3;
  background: #fff;
  top: 40%;
  right: 0;
  font-size: 24px;
  padding-top: 10px;
  padding-left: 5px;
}
.sidebar-closed-desc{
  width: 100px;
  display: none;
  position: absolute;
  font-size: 12px;
    padding-top: 7px;
    padding-left: 12px;
    color: #ffff;
  background: #00263E;
  cursor: default;
  height: 30px;
    top: -6px;
    left: 23px;
    border-radius: 12px;
}
.sidebar-closed:hover .sidebar-closed-desc{
  display: block;
}
/* SPINNNER FOR LEFT SIDEBAR HERE*/
.spinner_sideleft {
  margin-top: 40px;
  margin-left: 60px;
  width: 70px;
  text-align: center;
}

.spinner_sideleft > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner_sideleft .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner_sideleft .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.ImageLogoAccount{
  width: 100px;
  height: 100px;
}

.account-value-filter{
  width: 180px;
  margin-left: 253px;
  margin-top: 12px;
}
.each-lable-account{
  display: inline-block;


}
.each-value-account{
  display: inline-block;
  position: absolute;
  left: 170px;
}
.each-total-account{
  display: block;
  margin-top: 20px;
}
.container-account{
  margin-left: 25%;
  margin-top:1%;
  font-size: 14px;
}
.title-account{
  box-shadow: inset 0 -1px #e3e8ee;
  padding-bottom: 15px;
  margin-right: 30px;
}
.box-account{
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
  background: #fff;
  width: 700px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
  min-height: 150px;
}
.sidebar-account-each:hover{
  color: #396bf0;
}
.each-input-account{
  width: 150px;
  height: 20px;
  font-size: 12px;
  box-shadow: rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  border-radius: 4px;
    padding: 4px;
    margin-left: 109px;
}
.image-account{
  width: 280px;
    margin-left: 16%;
    margin-bottom: 48px;
    cursor: pointer;
}
.button-account{
  box-shadow: 0 0 0 1px #5469d4, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 2px;
    text-align: center;
    height: 20px;
    margin-top: 20px;
    margin-left: 180px;
    
    width: 80px;
    background: #5469d4;
    border: 12px;
    color: #fff;
}
.button-top-account{
  position: absolute;
    right: 29px;
    top: -2px;
}
.button-upgrade-account{  
  position: absolute;
margin-top: 0px;
right: 25px;
width: 200px;
}
.password-account{
  cursor: pointer;
    border-radius: 2px;
    text-align: center;
    height: 25px;
    
    width: 150px;
    display: inline-block;
    margin-left: 84px;
    background: #fff;
    color: #5469d4;
  box-shadow: 0 0 0 1px #5469d4, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  
}
.task-tabvalue2{
  color: #5469d4;
    display: inline-block;
    box-shadow: 0 0 0 1px #396bf0, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    margin-left: 17px;
    cursor: pointer;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 10px;
    font-weight: 600;
    width: 92px;
    text-align: center;
    padding: 5px 2px;
    border-radius: 3px;
}
.newpass-account{
position: absolute;
left: 60px;
}
.cancelpass-account{
  width: 80px;
  margin-left: 130px;
}
.cancelpayment-account{
  width: 80px;
  top: 18px;
}
.error-account{
  margin-left: 150px;
    margin-bottom: 10px;
    color: red;
    margin-top: 20px;
}
.form-account{
  width: 50%;
}
.label-account{
  margin: 20px 0;
}
.each-bottom-account{
  position: absolute;
  left: 50px;
}
.each-select-account{
  width: 150px;
  height: 20px;
  margin-left: 15px;
  display: inline-block;
}
.prorate-account{
  margin-top: 20px;
  display: inline-block;
}
.button-change-account{
  display: block;
  color: #5469d4;
  background: #fff;
  margin-bottom: 50px;
}
.final-account{
  border-top: 1px solid;
  padding-top: 10px;
}
.markerCluster {
  background: rgba(207, 0, 15, .5);
  border-radius: 40px;
  border: 2px solid rgba(207, 0, 15, .5);
  font-size: 10px;
  color: #fff;
  font-weight: 900;
  font-family: Open Sans;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
.current-locations-sidebar{
 padding-top: 20px;
 margin-left: 10px;
 width: 325px;
 border-bottom: 1px solid rgb(57, 107, 240,0.5);
 font-size: 12px;
 font-weight: bold;
 background: #fff; 
 font-family: "Source Sans Pro", Sans-serif;
 font-size: 15px;
 font-weight: 600;
 font-stretch: normal;
 font-style: normal;
 line-height: 1.27;
 letter-spacing: normal;
 color: #000000;
 padding-bottom: 10px;
}
.current-locations-sidebar-box{
  width: 100%;
  height: 70px;
  background: #ffffff;
  border-radius: 2px;
}
.current-locations-sidebar-box2{
  width: 100%;
  background: #ffffff;
  border-radius: 2px;
  padding-bottom: 5px;
}
.add-location-warning2{
  margin-bottom: 10px;
    height: 35px;
    margin-top: 15px;
}
.circle-remove-location{
  position: absolute;
    right: 27px;
    margin-top: -45px;
    font-size: 23px;
}
.name-locations-sidebar{
  padding-top: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
 padding-left: 10px;
 
}

.total-locations-sidebar{
  cursor: pointer;
  background: #fff;
}
.total-locations-sidebar:hover{
  background-color: #c5defa ;
}
.address-locations-sidebar{
  padding-top: 5px;
  font-size: 12px;
 padding-left: 10px;
 padding-bottom: 20px;
 
}
.switch-searchbar {

  display: inline-block;
  width: 26px;
  height: 18px;


    position: absolute;
    z-index: 8;
}



.slider-searchbar {
 width: 20px;
 cursor: pointer;
}



.switch-searchbar-pros{
  font-size: 11px;
  font-weight: bold;
  margin-left: -20px;
  margin-top: -67px;
  display: none;
  background: #eff6fe;
  border: solid 2px #396bf0;
  line-height: 1;
  width: 72px;
  text-align: center;
  height: 33px;
  padding-top: 3px;
  border-radius: 3px;
}
.switch-searchbar:hover .switch-searchbar-pros{
display: block;
}
.business-checker-name{
  display: inline-block;
    padding-left: 7px;
    position: absolute;
    font-size: 14px;
    font-weight: bold;
}
.business-checker-image{
  width: 22px;
    display: inline-block;
}
.business-checker-vicinity{
  font-size: 12px;
  font-weight: bold;
  width: 200px;
  margin-top: 5px;
}
.business-checker-vicinity-text{
  font-size: 12px;
  font-weight: normal;
  display: inline;
}
.business-checker-single{
  height: 30px;
}
.add-location-warning{
  margin-bottom: 10px;
  height: 35px;

}
.add-location-warning:hover{
  background: #fff;
}
.resend-email{
  position: absolute;
  right: 26px;
  margin-top: -22px;
}
.error-logger-blue{
  background: #eff6fe !important;
}
.error-logger-values{
  width: 220px !important;
    margin-top: 12px;
    
    right: 10px;
    position: absolute;
}
.sidebar-account-total{
  position: absolute;
  left: 30px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}
.sidebar-account-each{
  margin-top: 15px;
  width: 200px;
  height: 20px;
  /* padding-top: 12px; */
  padding-left: 16px;
}
.sidebar-account-selected{
  color: #5469d4;
}
.dropping-tot{
  display: block;
}
#dropping-searchbar{
  display: inline-block;
  width: 150px;
  height: 20px;
  border: 1px solid #cdcdcd;
  padding-left: 2px;
  margin-bottom: 10px;
}
.dropping-classvalue{
  padding: 0 10px;
  height: 20px;
  background: #0074D9;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  margin-left: 2px;
  display: inline-block;
  margin-bottom: 2px;
}
.marker-business{
  /* background-image: url('./Images/business_business_marker.png'); */
  background-size: cover;
  width: 35px;
  height: 40px;
  cursor: pointer;

}
.marker-prospect-business{
  /* background-image: url('./Images/business_marker.png'); */
  /* background-size: cover; */
  width: 35px;
  height: 40px;
  cursor: pointer;

}
.dropping-tag{
  
  border-bottom: 1px solid rgb(57, 107, 240,0.5);
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #000000;
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 180px;
}
#dropping-listrapper{
  margin-bottom: 5px;
}
#accountsub-questionmark{
  position: absolute;
    top: 22px;
    left: 141px;
}
.accountsub-expla{

}
#info-value-accountsub{
  display: none;
}
#accountsub-questionmark:hover~#info-value-accountsub{
  display: block;
}
#info-value-accountsub{
  top: -65px;
}
.each-lable-accountval{
  width: 350px;
  height: 45px;
  display: inline-block;
  margin-left: 45px;
}
td{
  padding: 15px 25px;
  text-align: center;
  
}
#users-table-account{
  margin-top: 20px;
  
  border-radius: 2px;
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
}
thead{
  background: #396bf0;
}
.users-table-verified{
  color: green;
}
.users-table-pending{
  color: #FF4500;
}
.users-table-header{
  color: #fff;
}
.cancel-delete-users{
  margin-left: 0px;
}
.button-account-user{
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 10px;
  
}
.box-account-users{
  width: 850px;
}
.task-box{
  height: 100%;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
  background-color: #ffffff;
}
.task-total{
  background: #eff6fe;
}
.task-total{
  position: fixed;
  width: 100%;
  height: 100%;
}
.leaflet-control-zoom-in{
  color: #396bf0 !important;
  background: #eff6fe !important;
}
.leaflet-control-zoom-out{
  color: #396bf0 !important;
  background: #eff6fe !important;
}







/* LEAFLET DRAWING SETUP  */

.leaflet-draw-edit-edit{
  display: none !important;
}
.leaflet-draw-section {
	position: relative;
}

.leaflet-draw-toolbar {
	margin-top: 12px;
}

.leaflet-draw-toolbar-top {
	margin-top: 0;
}

.leaflet-draw-toolbar-notop a:first-child {
	border-top-right-radius: 0;
}

.leaflet-draw-toolbar-nobottom a:last-child {
	border-bottom-right-radius: 0;
}

.leaflet-draw-toolbar a {
	/* background-image: url('images/spritesheet.png'); */
	/* background-image: linear-gradient(transparent, transparent), url('images/spritesheet.svg'); */
	background-repeat: no-repeat;
	background-size: 300px 30px;
	background-clip: padding-box;
}
.leaflet-draw-draw-polygon{
  /* background-image:  url('polygon.png'); */
  border-radius: 2px !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
  background:#eff6fe !important;
}
.leaflet-popup-content-wrapper{
  /* background-image:  url('polygon.png'); */
}
.leaflet-draw-toolbar{
  border: none !important;

}
.leaflet-retina .leaflet-draw-toolbar a {
	/* background-image: url('images/spritesheet-2x.png'); */
	/* background-image: linear-gradient(transparent, transparent), url('images/spritesheet.svg'); */
}

.leaflet-draw a {
	display: block;
	text-align: center;
	text-decoration: none;
}

.leaflet-draw a .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

/* ================================================================== */
/* Toolbar actions menu
/* ================================================================== */

.leaflet-draw-actions {
	display: none;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	left: 26px; /* leaflet-draw-toolbar.left + leaflet-draw-toolbar.width */
	top: 0;
	white-space: nowrap;
}

.leaflet-touch .leaflet-draw-actions {
	left: 32px;
}

.leaflet-right .leaflet-draw-actions {
	right: 26px;
	left: auto;
}

.leaflet-touch .leaflet-right .leaflet-draw-actions {
	right: 32px;
	left: auto;
}

.leaflet-draw-actions li {
	display: inline-block;
}

.leaflet-draw-actions li:first-child a {
	border-left: none;
}

.leaflet-draw-actions li:last-child a {
	-webkit-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.leaflet-right .leaflet-draw-actions li:last-child a {
	-webkit-border-radius: 0;
	border-radius: 0;
}

.leaflet-right .leaflet-draw-actions li:first-child a {
	-webkit-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.leaflet-draw-actions a {
	background-color: #919187;
	border-left: 1px solid #AAA;
	color: #FFF;
	font: 11px/19px "Helvetica Neue", Arial, Helvetica, sans-serif;
	line-height: 28px;
	text-decoration: none;
	padding-left: 10px;
	padding-right: 10px;
	height: 28px;
}

.leaflet-touch .leaflet-draw-actions a {
	font-size: 12px;
	line-height: 30px;
	height: 30px;
}

.leaflet-draw-actions-bottom {
	margin-top: 0;
}

.leaflet-draw-actions-top {
	margin-top: 1px;
}

.leaflet-draw-actions-top a,
.leaflet-draw-actions-bottom a {
	height: 27px;
	line-height: 27px;
}

.leaflet-draw-actions a:hover {
	background-color: #A0A098;
}

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
	height: 26px;
	line-height: 26px;
}

/* ================================================================== */
/* Draw toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
	background-position: -2px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
	background-position: 0 -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
	background-position: -31px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
	background-position: -29px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
	background-position: -62px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
	background-position: -60px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle {
	background-position: -92px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
	background-position: -90px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker {
	background-position: -122px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
	background-position: -120px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
	background-position: -273px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
	background-position: -271px -1px;
}

/* ================================================================== */
/* Edit toolbar
/* ================================================================== */

.leaflet-draw-toolbar .leaflet-draw-edit-edit {
	background-position: -152px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
	background-position: -150px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove {
	background-position: -182px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
	background-position: -180px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
	background-position: -212px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
	background-position: -210px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
	background-position: -242px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
	background-position: -240px -2px;
}

/* ================================================================== */
/* Drawing styles
/* ================================================================== */

.leaflet-mouse-marker {
	background-color: #fff;
	cursor: crosshair;
}

.leaflet-draw-tooltip {
	background: rgb(54, 54, 54);
	background: rgba(0, 0, 0, 0.5);
	border: 1px solid transparent;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	color: #fff;
	font: 12px/18px "Helvetica Neue", Arial, Helvetica, sans-serif;
	margin-left: 20px;
	margin-top: -21px;
	padding: 4px 8px;
	position: absolute;
	visibility: hidden;
	white-space: nowrap;
	z-index: 6;
}

.leaflet-draw-tooltip:before {
	border-right: 6px solid black;
	border-right-color: rgba(0, 0, 0, 0.5);
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	content: "";
	position: absolute;
	top: 7px;
	left: -7px;
}

.leaflet-error-draw-tooltip {
	background-color: #F2DEDE;
	border: 1px solid #E6B6BD;
	color: #B94A48;
}

.leaflet-error-draw-tooltip:before {
	border-right-color: #E6B6BD;
}

.leaflet-draw-tooltip-single {
	margin-top: -12px
}

.leaflet-draw-tooltip-subtext {
	color: #f8d5e4;
}

.leaflet-draw-guide-dash {
	font-size: 1%;
	opacity: 0.6;
	position: absolute;
	width: 5px;
	height: 5px;
}

/* ================================================================== */
/* Edit styles
/* ================================================================== */

.leaflet-edit-marker-selected {
	background-color: rgba(254, 87, 161, 0.1);
	border: 4px dashed rgba(254, 87, 161, 0.6);
	-webkit-border-radius: 4px;
	border-radius: 4px;
	box-sizing: content-box;
}

.leaflet-edit-move {
	cursor: move;
}

.leaflet-edit-resize {
	cursor: pointer;
}

/* ================================================================== */
/* Old IE styles
/* ================================================================== */

.leaflet-oldie .leaflet-draw-toolbar {
	border: 1px solid #999;
}
.leaflet-top {
  margin-top: 100px;
  margin-left: 15px;
}




/* TASK MANAGEMENT TOTAL */
.task-sidebar-total{
 width: 250px;
 background: #00263e;
 height: 100%; 
 display: inline-block;
 position: fixed;
 z-index: 4;
}
.task-gridlayout{
  width: 100%;
  top: 100px;
  height: 100%;
  display: inline-block;
  position: absolute;
  /* left: 250px; */
}
.task-manager-logo{
  position: relative;
  width: 140px;
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}
.task-sidebar-close{
  width: 25px;
  height: 25px;
  border-radius: 17px;
  border: .5px solid #fff;
  display: inline-block;
  margin-left: 52px;
  position: absolute;
  margin-top: 20px;
  cursor: pointer;
}
#task-sidebar-close-arrow{
  margin-left: 7px;
  margin-top: 1px;
  color: #fff;
  font-size: 20px;
}
.task-manager-log{
  height: 70px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #fff;
}


.task-sidebar-tags{
  width: 188px;
  cursor: pointer;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-top: 20px;
  margin-left: 13px;
  display: inline-block;
}
.task-sidebar-down{
  
  color: #396bf0;
  display: inline-block;
  font-size: 20px;
  position: absolute;
  margin-left: 15px;
  cursor: pointer;
  margin-top: 20px;
}
.task-sidebar-up{
  color: #fff;
  display: inline-block;
  font-size: 20px;
  position: absolute;
  margin-left: 15px;
  cursor: pointer;
  margin-top: 20px;
}
.task-sidebar-tags-each{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 14px;
  margin-left: 15px;
  margin-bottom: 7px;
  cursor: pointer;
}
.task-sidebar-tags-total {
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-top: 20px;
  
  display: inline-block;
  width: 219px;
}
.task-top-threedots2{
  position: absolute;
  right: 20px;
  text-align: center;
  width: 40px;
  padding: 3px;
  border-radius: 3px;
  margin-top:3px;
  cursor: pointer;
  /* box-shadow: 0 0 0 1px #396bf0, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08); */
}
.container2 .search2{
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 40px;
    /* background: #eff6fe; */
    border-radius: 1px;
    /* transition: all 1s; */
    z-index: 4;

}
.margin-left10{
  margin-left: 13px;
  margin-top: 4px;
}
.boxdata-percentval-total{
width: 80%;
display: inline-block;
}
.boxdata-percentval{
  display: inline-block;
  font-size: 17px;
  padding-left: 10px;
}
.box-popuppyscho-eachvalue{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  width: 150px;
}
.box-popuppyscho-eachvalue-second{
  font-size: 13px;

}
.box-popuppyscho-eachvalue2{
  font-size: 15px;
  display: inline-block;
  width: 45%;
  /* text-align: right; */
  padding-right: 25px;
  color: #396bf0;
}
.box-popuppyscho-eachvalue-expense{
  font-size: 13px;

}
.boxdata-popup-total{
    display: grid;
    grid-template-columns: 1fr;
    width: 440px;
}
.margin-right15{
  margin-right: 15px;
}
.box-containerfull{
    width: 404px;
    border-radius: 2px;
    background-color: #ffffff;
    height: 120px;
    vertical-align: top;
    padding-left: 10px;
    padding-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;   
}
.Psychographic-grid{
  display: grid;
  grid-template-columns: .5fr 1.5fr; 
  height: 80%;
}
.chart-legend li span{
  display: inline-block;
  height: 50px;
  margin-right: 5px;
}
.box-containerfull2{
  width: 404px;
  border-radius: 2px;
  background-color: #ffffff;
  min-height: 140px;
  vertical-align: top;
  padding-left: 10px;
  padding-top: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  
}
.display-block{
  display: block;
}
.display-inline-block{
  display: inline-block;
}
.display-none{
  display: none;
}
.polarchart-total{
  width: 100%;
  height: 400px;
  margin-top: 12px;
  font-size: 16px;
}

.polarchart-container-competitor {
  height: 455px;
  width: 100%;
  position: relative; 
}

.box-goback-container{
  font-weight: bold;
    cursor: pointer;
    margin-top: 25px;
}
.box-goback-container2{
  font-weight: bold;
  cursor: pointer;
}
.box-container-interestseach{
  font-size: 12px;
    display: inline-block;
    /* width: 39%; */
    text-align: right;
    padding-right: 15px;
    color: #8d8d8d;
}
.box-industry-commercialtotal
{
  /* margin-top: 5px; */
  display: grid;
  grid-template-columns: 50% 50%;
}
.box-industry-commercialeach{
  text-align: left;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
}
.box-popuppyscho-allvalues{
  width: 290px;
  display: inline-block;
}
.box-popuppyscho-racetotal{
  display: inline-block;
  width: 130px;
}
.box-industry-commercialval{
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: normal;
  color: #396bf0;
  text-align: right;
  padding-right: 5px;
}
.box-container-interestseach3{
  font-size: 15px;
  display: inline-block;
  font-weight: bold;
  text-align: right;
  padding-right: 15px;
}
.box-container-interests{
  width: 80%;
    display: inline-block;
    margin-bottom: 15px;
    margin-left: 75px;
}
.box-container-values{
  padding-top: 30px;
}
.box-showmore2{
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  right: 35px;
  border: 1px solid rgb(99,57,240);
  width: 100px;
  text-align: center;
  margin-top: -8px;
  height: 20px;
  border-radius: 4px;
  color: rgb(99,57,240);
  margin-top: -1px;
}
.box-showmore2hide{
  margin-top: -17px;
}
.react-class-filter{
  width: 408px;
  min-height: 65px;
  margin-bottom: 15px;
  background: #fff;
}
.react-class-filter-name{
  display: inline-block;
  margin-left: 10px;
  font-family: "Source Sans Pro", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    color: #000000;
    margin-top: 16px;
    position: absolute;
}

.react-dropdown-selectval{
  width: 150px;
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 10px;
}
.react-dropdown-selectval2{
  width: 150px;
  display: inline-block;
  margin-left: 60px;
  margin-top: 12px;

}
.box-container-values2{
  
    grid-column-start: 1;
    grid-column-end: 3;
}
.box-container-racetotal{
  display: inline-block;
  width: 136px;
  margin-right: 23px;
}
.box-container-race{
  font-size: 16px !important;
  width: 80px !important;
  display: inline-block;
    position: absolute;
}
.box-container-racetype{
  font-size: 11px;
  width: 79%;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #8d8d8d;
  display: inline-block;
}
.box-container-racetype2{
  font-size: 15px;
  width: 79%;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #8d8d8d;
  display: inline-block;
}

.box-popuppyscho-racetype2{
  font-size: 15px;
  width: 79%;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #8d8d8d;
  display: inline-block;
}
.box-container-allvalues{
  position: absolute;
  width: 324px;
  margin-left: 75px;
  display: inline-block;
}
.boxdata-perentcolor{
  color: rgb(99,57,240);
  width: auto;
  padding-left: 0px;
}
.box-container-racevalue{
  font-size: 15px;
  display: inline-block;
  color: #396bf0;

}
.box-popuppyscho-racevalue{
  font-size: 15px;
  display: inline-block;
  color: #396bf0;

}
.box-container-eachvalue{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1.46;
  letter-spacing: normal;
  color: #000000;
  width: 50%;
}
.box-container-eachvalue2{
  font-size: 15px;
  display: inline-block;
  width: 50%;
  text-align: right;
  padding-right: 25px;
  color: #396bf0;
}
.boxdata-percentval2{
  color: #396bf0;
  display: inline-block;
  font-size: 18px;
  width: 18%;
  text-align: right;
  
}

#street-view-container{
  width: 100%;
  height: 100%;
}


.sidebar-stats-total{
  z-index: 3;
  width: 400px;
  position: absolute;
  height: 400px;
  /* background: rgb(239, 246, 254, 1); */
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 14px;
  transform: translateX(0px);
  transition: transform 0.3s;
  top: 95px;
  left: 30px;
}

.sidebar-stats-total-hideval{
  transform: translateX(-450px);
  transition: transform 0.3s;
}

.close-button{
  background: none;
  border: none;
  font-size: 35px;
  cursor: pointer;
  color: rgb(167, 167, 167);
  text-align: right;
  width: 95%;
}

.Building-total{
  z-index: 2;
  width: 300px;
  position: absolute;
  /* background: rgb(239, 246, 254, 1); */
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  font-family: "Source Sans Pro", Sans-serif;
    font-size: 14px;
    transform: translateX(0px);
    transition: transform 0.3s;
  /* top: 95px; */
  left: 105px;
}
.Building-each2{
  font-size: 12px;
  padding-bottom: 3px;
  text-align: center;
  color: #a7a7a7;
  cursor: pointer;
}
.Layer-filter-name{
  text-align: center;
  font-size: 12px;
  color: #a7a7a7;
  background: rgb(239, 246, 254, .9);
  padding-top: 3px;
  margin-bottom: 2px;
}
#Layer-filter-total{
  position: absolute;
  z-index: 3;
  background: rgb(213, 225, 241);
  width: 70px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  top: 55px;
}
.Layer-filter-each{
  background-color: rgb(239, 246, 254, .9);
  margin-bottom: 1px;
  cursor: pointer;
  min-height: 50px;
}
.Layer-filter-lock-freetier{
  font-size: 30px;
  color: #a7a7a7;
  width: 35px;
  height: 25px;
  margin: 9px 0px -4px 25px;
  cursor: pointer;
}

.Layer-filter-lock-freetier-fss{
  font-size: 30px;
  color: #a7a7a7;
  cursor: pointer;
  position: absolute;
  left: 10px;
  margin-top: -5px;
}
.Layer-filter-lock-freetier-legend{
  font-size: 30px;
  color: #a7a7a7;
  cursor: pointer;
  position: absolute;
  margin-left: -40px;
}

.Layer-filter-lock-freetier-sidebar{
    font-size: 20px;
    position: absolute;
    color: #a7a7a7;
    cursor: pointer;
    margin-top: 14px;
    margin-left: -20px;
}

.Layer-filter-lock-freetier-sidebar1{
  font-size: 20px;
  position: absolute;
  color: #a7a7a7;
  cursor: pointer;
  margin-top: 14px;
  margin-left: -40px;
}

.Competitor-sidebar-image-layer{
  width: 35px;
    height: 25px;
    margin: 9px 0px -4px 15px;
    cursor: pointer;
}
.Competitor-sidebar-image-layer rect{
  fill:  #a7a7a7;

}
.Competitor-sidebar-image-layer path{
fill:  #a7a7a7;
  /* stroke: #a7a7a7; */
    stroke-width: 8px;
}
.Building-imagemain-layer{
  width: 35px;
    height: 25px;
    margin: 9px 0px -4px 15px;
    cursor: pointer;
}

.Layer-border-right{
  max-height: 0;

}
.Layer-border-right-blue {
  background: #158cbf;

    height: 100%;
    overflow-y: hidden;
    max-height: 53px; 
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      width: 3px;
      position: absolute;
      right: 0;
}
.Building-imagemain-layer path{
  fill:  #158cbf;
    /* stroke: #158cbf; */
      stroke-width: 8px;
  }
  .Building-imagemain-layer rect{
    fill:  #158cbf;
  
  }
  .Building-imagemain-layer-blue{
    width: 35px;
      height: 25px;
      margin: 9px 0px -4px 15px;
      cursor: pointer;
  }
  .Building-imagemain-layer-blue path{
    fill:  #0c4e6a;
      /* stroke: #0c4e6a; */
        stroke-width: 8px;
    }
    .Building-imagemain-layer-blue rect{
      fill:  #0c4e6a;
    
    }
.Building-each{
  padding: 3px 20px;
  cursor: pointer;
  padding: 3px 0 3px 12px;
}
.Building-each-selected{
  color: #158cbf;
}
.Building-each-selected-blue{
  color: #0c4e6a;
}
.Building-total-each{
  height: 345px;
  overflow-y: scroll;
  transition: height .5s;
}
.Building-total-hide{
  height: 0px;
}
.Building-total-hideval{
  transform: translateX(-420px);
    transition: transform 0.3s;
}
.Building-deselect{
  width: 84px;
  height: 21px;
  /* margin-left: 2px; */
  text-align: center;
  background: #4169ea;
  /* padding-top: 2px; */
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.business-link{
  width: 100%;
    text-align: center;
    margin: 5px 0;
}
.Building-result-none{
  text-align: center;
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 600;
  color: red;
}
.Building-close{
  text-align: center;
  font-size: 14px;
  padding: 3px 5px 0px 5px;
  font-weight: 700;
  height: 30px;
}
.Building-close-container{
  position: relative;
    top: -5px;
}
.Building-title {
  border-bottom: rgb(213, 225, 241) 1.5px solid;
  font-size: 16px;
  padding: 5px;
  height: 35px;
  font-weight: 700;
}
.Building-each:hover{
  background: #c5defa;
}
.Building-name{
  position: relative;
    display: inline;
    color:  #158cbf;
    top: -10px;
}
.Building-name2{
  position: relative;
  display: inline;
  color: #a7a7a7;
  top: -10px;
}
.Building-image{
  width: 25px;
  margin-right: 20px;

}
.Building-imagemain{
  width: 45px;
  height: 30px;
  margin: 8px 5px 0px 5px;
  cursor: pointer;
}
.Building-imagemain path{
  fill:  #158cbf;
    stroke: #158cbf;
      stroke-width: 8px;
  }
  .Building-imagemain rect{
    fill:  #158cbf;
  
  }
.container-marginleft{
  margin-left: 15px;
}
.task-top-title{
  width: 100%;
  position: absolute;
    left: 10px;
    top: 15px;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 24px;
    font-weight: bold;
}
.task-secondary-title{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-left: 5px;
  border-bottom: 1.5px solid #396bf0;
}
.task-addmore{
  z-index: 2;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
  background-color: #ffffff;
  text-align: center;
  font-size: 31px;
  display: inline-block;
  color: #396bf0;
  /* margin-top: 6px; */
  cursor: pointer;
}
.task-addmore-one{
  
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  text-align: center;
  font-size: 31px;
  display: inline-block;
  color: #396bf0;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.task-top-threedots{
  display: inline-block;
    margin-left: 10px;
    color: #396bf0;
    cursor: pointer;
    font-size: 22px;
}
.task-options-total{
  display: inline-block;
  margin-left: 25px;
  width: 140px;
  position: absolute;
  top: 16px;
  border-radius: 1px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.36);
  border: solid 0.5px #396bf0;
  background-color: #ffffff;
  padding: 5px 0px;
  z-index: 4;
}
.task-options-total2{
  display: inline-block;
  margin-left: 25px;
  width: 140px;
  position: absolute;
  right: 50px;
  top: 75px;
  border-radius: 1px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.36);
  border: solid 0.5px #396bf0;
  background-color: #ffffff;
  padding: 5px 0px;
  z-index: 4;
}
.task-options-each:hover{
  background: #c5defa;
}

.task-options-each{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  cursor: pointer;
  padding:1px 20px;
  letter-spacing: normal;
  color: #2f2f2f;
}
.task-search-background{
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}
.layer-toggle-switch {
  display: inline-block;
  width: 40px;
  height: 25px;
  position: absolute;
  top: 5px;
    right: 5px;
}
.layer-toggle-switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.layer-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .layer-toggle-slider {
  background-color: #40ad25;
}

input:focus + .layer-toggle-slider {
  box-shadow: 0 0 1px #40ad25;
}

input:checked + .layer-toggle-slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.layer-toggle-slider.layer-toggle-round {
  border-radius: 34px;
}

.layer-toggle-slider.layer-toggle-round:before {
  border-radius: 50%;
}
.layer-toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}



.task-options-input{
  left: 30%;
    position: absolute;
    top: 30%;
}
.task-options-creating-total{
  width: 600px;
  height: 340px;
  background: #c5defa;
  
  padding: 25px;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.task-options-name{
  width: 280px;
  height: 25px;
  font-size: 14px;
  border: 1px solid lightgrey;
}

.task-options-name-label{
  margin: 8px 0px;
}
#task-createbutton{
  margin-left: 170px;
  margin-top: 18px;
}
.task-sidebarshow{
  position: absolute;
  width: 82.5%;
  left: 250px ;
  height: 100%;
}
.task-gridlayout-one{
  left: 250px;
  /* width: 70%; */
}
.task-sidebarhide{
  position: absolute;
  width: 85%;
  left: 30px;
}
.sidebar-task{
  left: 0;
  color: #396bf0;
}
#tasks-next{
  width: 30px;
    height: 30px;
    border: 1.5px solid black;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 3px;
    color: black;
    font-weight: bold;
}
#tasks-back{
  width: 30px;
    height: 30px;
    border: 1.5px solid black;
    font-size: 17px;
    border-radius: 50px;
    color: black;
    padding-right: 3px;
    font-weight: bold;
}
.tasks-pages{
  display: inline-block;
  width: 15px;
  height: 16px;
  border: 3px solid black;
  border-radius: 65px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.tasks-pages-value{
  display: inline-block;
  width: 15px;
  height: 16px;
  border: 3px solid #396bf0;
  border-radius: 65px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}
.task-center-filter{
  height: 169px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 35%;
}
.task-middle-threedots{
  color: grey;
  position: absolute;
  right: 5px;
}
.task-center-filter-left{
  margin-left: 250px;
  margin-top: 38px;
  height: 60px;
}
.tasks-value-name{
  display: inline-block;
  width: 92%;
  font-size: 18px;

}
.tasks-opstions-total-next{
  margin-left: -155px;
  margin-top: -70px;
}
.tags-searchvalue{
  background: #c5defa;
}
#task-createbutton-next{
  margin-top: 150px;
    margin-right: 200px;
    margin-left: 200px;
    margin-bottom: 32px;

}
.tasks-business-locations{
  position: absolute;
  margin-left: 83px;
  margin-top: 24px;
  font-weight: bold;
}
.task-new-total{
  width: 60%;
  height: 85%;
  background: #fff;
  position: absolute;
  margin-left: 20%;
  top: 8%;
  transition: all .2s ease-in-out;
  border-radius: 2px;
  overflow: scroll;
  padding: 12px 20px;
}
.task-new-name{
  width: 250px;
  height: 30px;
  border-bottom: 1px solid lightgrey;
  color: #396bf0;
  font-size: 24px;
  font-weight: bold;
  outline: #000000;
}
.task-value-select-email{
  display: inline-block;
  margin-top: 35px;
  margin-left: 24px;
}
.task-tabvalue2-color{
  color: #fff !important;
  background: #5469d4;
}
.task-value-input{
    width: 98%;
    padding-bottom: 10px;
  height: 35px;
  border-bottom: 1px solid #000000;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  outline: #000000;
}
.task-body-name{
  padding: 0 15px;
  width: 100%;
  height: 100%;
  overflow: scroll;
  
}
.task-draggable{
  width: 100%;
  height: 25px;
  cursor: grab;
}

.task-draggable:active{
  cursor: grabbing;
}
.task-calendar{
  position: absolute;
  z-index: 8;
}
.piechart-horizontal{
  min-height: 280px;
}
.task-calendar-type{
  background: #fff;
  top: -80px;
  position: absolute;
}
.task-calendar-total{
  display: inline-block;
  position: absolute;
  /* width: 100%;
  top: 125px;
  background: #fff; */
}
.task-big-calendar-total{
  display: inline-block;
  position: absolute;
  width: 95%;
  margin-left: 20px;
  top: 125px;
  background: #fff;
}
.task-calendar-totaleach{
  display: inline-block;
}
.react-calendar{
  width: 255px !important;
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
  
  left: 11%;
    top: 80px;
  border-radius: 7px !important;
  background: #fff !important;
  font-family: "Source Sans Pro", Sans-serif !important;
  line-height: 1.125em;
  border: none !important;
  z-index: 2;
}
.task-value-text{
  cursor: pointer;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  margin-left: 6px;
  margin-top: 10px;
}
.task-value-calendar{
  opacity: 0.5;
    width: 24px;
    display: inline-block;
    margin-top: 12px;
}
.task-value-text-due{
  cursor: pointer;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: .6;
  display: inline-block;
  position: absolute;
  margin-left: 6px;
  margin-top: 24px;
}
.task-value-total{
  width: 30%;
  display: inline-block;
  margin-top: 5px;
  margin-left: 10px;
}
.task-value-select{
  width: 100px !important;
    position: absolute !important;
    font-size: 10px;
    top: 79px;
    margin-left: 25px;
    border: none !important;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    
}
.task-value-select4{
  width: 100px !important;
    position: absolute !important;
    font-size: 10px;
    top: 79px;
    margin-left: 25px;
    border: none !important;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    
}
.task-value-select2{
  width: 100px !important;
    position: absolute !important;
    font-size: 10px;
    top: 70px;
    margin-left: 25px;
    border: none !important;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    
}
.task-value-select3{
  width: 100px !important;
    font-size: 10px;
    margin-left: 25px;
    border: none !important;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 14px;
    font-weight: 600;
    
}
.task-value-textarea{
  width: 100%;
  height: 55px;
  border: 1px solid lightgrey;
  margin-top: 15px;
  font-size: 12px;
  padding: 1px 3px;
}
.task-value-more{
  width: 50px;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  right: -17px;
  bottom: 10px;
}
.react-dropdown-select-item {
color: black;
}
.task-label-total{
  border: 1px solid lightgrey;
    margin-top: 5px;
    overflow: scroll;
    max-height: 54px;
}
.task-label-input{
  height: 22px;
    font-size: 13px;
    font-family: "Source Sans Pro", Sans-serif;
    font-weight: 400;
    display: inline-block;
    margin-left: 8px;
    min-width: 70px;
}
.task-results-values{
  width: 368px;
  z-index:3;
  position: absolute;
  background: #fff;
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  font-family: "Source Sans Pro", Sans-serif;

}
.task-results-selected{
  display: inline-block;
    
}
.search-result-each{
  height: 22px;
  padding-left: 6px;
}
.search-result-each:hover{
  background: rgba(0,116,217,0.1);
  cursor: pointer;
}
.task-results-selected-each{
  display: inline-block;
  margin-left: 5px;
  padding: 3px;
  background: #0074D9;
  border: 6px;
  color: #fff;
}
.fa-times{
  cursor: pointer;
}
.github-picker{
  width: 212px !important;
  position: absolute !important;
  margin-top: 25px;
  margin-left: -20px;
  z-index: 3;
}
ins{
  display: none;
}
.task-label-name{
  display: inline-block;
  cursor: pointer;
}
.task-add-user-plus{
  width: 35px;
  height: 35px;
  border-radius: 19px;
  background: #396bf0;
  color: #fff;
  font-size: 24px;
  text-align: center;
  padding-top: 3px;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
}
.task-add-user-total{
  bottom: 8px;
  margin-top: 10px;
  /* position: absolute; */
  z-index: 0;
}
.task-add-user-total2 {
  bottom: 8px;
  margin-top: 10px;
  z-index: 0;
}
.task-results-absolute{
  position: absolute;
  background: #fff;
  width: 300px;
  margin-top: -40px;
  margin-left: 79px;
  /* padding-left: 5px; */
  padding-top: 2px;
  font-size: 12px;
}
.task-add-img{
  font-size: 23px;
  display: inline-block;
}
.task-menupost{
  width: 18px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.tasks-opstions-total-next2{
  right: 27px;
}
.task-sidebar-clicked{
  color: #396bf0;
}
.task-value-inputbox{
  width: 95%;
}
.task-menubox{
  position: absolute;
  width: 18px;
    cursor: pointer;
  margin-right: 10px;
}
.tasks-opstions-total-next3{
  right: 58px;
}
.task-value-grap{
  display: grid;
    grid-template-columns: 1.1fr 1fr;
    grid-gap: 20px 50px;
    margin-top: 10px;
}
.task-value-row{
  grid-column-start: 1;
    grid-column-end: 1;
}
.task-value-row3{
  grid-column-start: 2;
  grid-column-end: 2;
}
.task-value-row4{
  grid-column-start: 1;
  grid-column-end: 2;
}

.task-add-user-name{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  display: inline-block;
  
}
.task-add-user-name2{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  position: absolute;
  
}
.task-label-checklistinput{
  height: 25px;
    font-size: 13px;
    font-family: "Source Sans Pro", Sans-serif;
    font-weight: 400;
    display: inline-block;
    padding-left: 3px;
    outline: 1px solid lightgrey;
    border: 1px solid lightgrey;
    width: 100%;
    
}
.task-label-additem{
  box-shadow: 0 0 0 1px #5469d4, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
    height: 20px;
    width: 80px;
    background: #5469d4;
    border: 12px;
    color: #fff;
    margin-top: 8px;
}
.task-exittotal{
  position: absolute;
    right: 0;
    top: 0;
    border: 2px solid;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 27px;
    font-size: 20px;
    padding-top: 2px;
    background: #fff;
    margin-top: -16px;
    margin-right: -15px;
}
.task-checklist{
  display: inline-block;
    margin-left: 20px;
    margin-right: 30px;
}
.task-checktotal{
  box-shadow: 1px 0px 0px 0px rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
    background: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    margin-top: 5px;
}
.task-checklistdelete{
  position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    font-size: 20px;
    margin-right: 10px;
}
.task-checkcontainer{
  border: 1px solid lightgrey;
  padding: 5px;
  box-shadow: 0px 0px 0px 0px rgba(60,66,87, 0.12), 0 1px 6px 0 rgba(0,0,0, 0.12);
  overflow-y:scroll;
  max-height: 120px;
  min-height: 50px;
}
.task-subtask-input{
  width: 18px;
    position: absolute;
    cursor: pointer;
    margin-left: -7px;
}

.task-checklistvalue{
position: relative;
}
.task-add-userval{
  margin-top: 16px;
  border-bottom: 2px solid lightgrey;
  width: 100%;
  padding-bottom: 7px;
}
.task-message-data{
  color: grey
}
.task-message-box{
  width: 100%;
  height: 250px;
  position: relative;
  overflow: scroll;
}
.task-label-aticon{
  position: absolute;
  width: 19px;
  right: 0;
  margin-right: 10px;
  margin-top: 3px;
  opacity: .45;
  cursor: pointer;
}

.task-results-values2{
  width: 368px;
  z-index:3;
  position: absolute;
  background: #fff;
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  font-family: "Source Sans Pro", Sans-serif;
}

.task-message-overall{
  width: 100%;
  margin-top: 2px;
  color: #fff;
}
.task-message-container{
  max-width: 300px;
  margin-left: auto;
  margin-right: 0;
    right: 0;
    background: #396bf0;
    padding: 6px;
    border-radius: 3px;
}
.task-message-container2{
  max-width: 300px;
    right: 0;
    background: rgb(252, 203, 0);
    padding: 6px;
    border-radius: 3px;
}
.task-desc-total{
  border-bottom: 1px solid;
  text-align: right;
  height: 33px;
    margin-bottom: 15px;
}
.task-sidebar-down2 {
  color: #396bf0;
  display: inline-block;
  font-size: 20px;
  /* position: absolute; */
  /* margin-left: 15px; */
  cursor: pointer;
  /* margin-top: 20px; */
  /* margin-right: auto; */
  margin-left: 0;
  text-align: right;
  width: 72%;
}
#task-message-all::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
#task-message-all::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.task-checkcontainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.task-checkcontainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.task-exittotal2{
  position: absolute;
    border: 2px solid;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 27px;
    font-size: 23px;
    padding-top: 2px;
    margin-top: 3px;
    background: #fff;
    margin-left: 78.5%;
    z-index: 3;
    top: 4%;
}
.task-sidebar-up2 {
  color: #5469d4;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  margin-left: 0;
  text-align: right;
  width: 72%;
}
.margin-left150{
  margin-left: 15%;
}
.task-tabluecolor{
  color: #fff !important;
  background: #396bf0;
}
.task-tabvalue{
  color: #396bf0;
  box-shadow: 0 0 0 1px #396bf0, 0 2px 5px 0 rgba(42,47,69, 0.3), 0 4px 9px 0 rgba(42,47,69, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  margin-left: 15px;
  cursor: pointer;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  /* border-bottom: 1px solid; */
  width: 90px;
  text-align: center;
  /* padding-bottom: 5px; */
  padding: 6px;
  /* background: #fff; */
  border-radius: 3px;
}
.task-searchvalue{
  background: transparent;
  width: 250px;
  height: 25px;
  font-size: 18px;
  padding-bottom: 10px;
  padding-left: 2px;
  border-bottom: 1.5px solid ;
  box-shadow: 0px 6px 3px -2px rgba(0, 0, 0, 0.1);
}
.task-searchvalue-container{
  
  position: absolute;
  right: 65px;
  margin-top: 4px;
  box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.08);
}
.task-searchvalue-close{
  position: absolute;
  right: -2px;
  color: black;
  top: 2px;
  font-size: 25px;
}
.task-results-search-total{
  position: absolute;
  margin-left: 60px;
  margin-top: 80px;
}
.task-searchvalue:focus {
  border-bottom: 1.5px solid #396bf0 !important;
}
/* .task-calendar-total{
  position: absolute;
  top: 125px;
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 15px;
} */
.task-new-total::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.task-new-total::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.task-rednotification{
  background: rgb(255,50,50);
  border-radius: 20px;
  /* -moz-box-shadow: 0 0 20px rgb(255,50,50);
  -webkit-box-shadow: 0 0 20px rgb(255,50,50);
  box-shadow: 0 0 20px rgb(255,50,50); */
  width: 30px;
  height: 30px;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  font-family: Open Sans;
  text-align: center;
  padding-top: 3px;
  cursor: pointer;
  position: absolute;

}
.task-rednotification-total{
  top: -24px;
  right: 27px;
}
.task-rednotification-message{
  margin-top: 15px;
  left: 100px;
  cursor: default;
}
.task-rednotification-checklist{
  margin-top: -29px;
    left: 108px;
}
.task-rednotification-inside{
  cursor: default;
    width: 25px;
    height: 25px;
    font-size: 16px;
}
#mapbox{
  height: 500px;
  /* width: 80%; */
  /* margin-left: 10%; */
  width: 100%;
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: 4;
}
#mapbox-main{
  top: 0;
  bottom: 0;
  width: 100%;
  
}
.mapbox-gl-draw_point{
  display: none !important;
}
.Competitor-upgrade-total{
  width: 500px;
  height: 500px;
}
.Competitor-upgrade-value{
  color: #fff;
    font-size: 21px;
    text-align: center;
    margin: 20px 0;
}
.Competitor-research-started2 {
  background-color: #FF9800!important;
  padding: 20px 40px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 170px;
  margin-top: 50px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
.Competitor-box-lock2 {
  font-size: 70px;
  text-align: center;
  margin-top: 178px;
  color: #034a77;
  cursor: pointer;
  height: 120px;
}
#mapboxlocked{
  height: 500px;
  background: #fff;

  /* width: 80%; */
  /* margin-left: 10%; */
  width: 100%;
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: 4;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib{
  display: none;
}
.Competitor-open{
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
  left: 0px;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
}
.Competitor-search-region-total{
  position: absolute;
  left: 100px;
  background: #fff;
  padding: 10px 15px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 3px 1px 10px 2px rgba(0, 0, 0, 0.2);
}
.Competitor-result-filter {
  border-radius: 2px;
  box-shadow: 0 6px 6px -1px rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 200px;
  background: #fff;
  margin-top: 10px;
}
.Competitor-result-each2 {
  font-size: 16px;
  padding-top: 6px;
  cursor: pointer;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
}
.Competitor-search-region{
  width: 200px;
}
.Competitor-padding20{
  padding: 20px 0;
}
.Competitor-close{
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  left: 0px;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .task-value-select4 {
   top: 125px;
  }
  .task-gridlayout{
    top: 135px;
  }
  .Competitor-upgrade-total {
    width: 365px;
  }
  .Competitor-research-started2{
    margin-left: 100px;
  }
  .task-center-filter-left{
    margin-top: 83px;
  }
  .task-center-filter{
    left: 10%;
  }
  .task-new-total{
    width: 100%;
  }
  .task-new-total{
    margin-left: 0px;
  }
  .login100-form-avatar{
    margin-left: 0px;
  }
  .login-logo-fremium-total{
    left: 10px !important;
  }
  .login-logo-login{
    font-size: 35px;
  }

  .task-options-input {
  left: 0;
  }
  .search-results-total {
  width: 250px !important;
  }
  .search-bar-search{
    left: 230px;
  }
  .switch-searchbar {
    left: 272px;
  }
}
@media only screen and (max-width: 600px){
  #sidebar-total{
    width: 100%;
    z-index: 4;
  }
  .box-container{
    width: 50% !important;

  }
  .box-container2{
    width: 100% !important;
  }
  .container-marginleft{
    margin-left: 0px !important;
  }
  .boxdata-container{
    width: 50%;
  }
  .sidebar-open{
    z-index: 5 !important;
    right: auto;
    top: 7%;
  }
  .topbar-total-each {
  width: 75px;
  font-size: 11px;
  margin-bottom: 50px;
  }
  .sidebar-realestate-total{
    margin-left: 0px;
  }
  .boxdata-percentval2{
    width: 0px;
  }
  .box-containerfull2{
    padding-left: 0px;
  }

  #search-here{
    right: 15%;
    top: 10px;
  }
  .task-calendar-total{margin-top: 150px;
    background: #fff;
  }
  .task-exittotal2{
    right: 0;
  }
  .sidebar-account-total {
    /* left: -10px;
    font-size: 10px; */
    display: none;
  }
  .form-inner {
    left: 2% !important;
  }
  .login-image{
    display: none;
  }
  .wrap-login100{
    margin-right: 0 !important;
    width: 90% !important;
  }
}

.Competitor_research-input{
  height: 40px;
  width: 780px;
  font-size: 18px;
  border-radius: 20px;
  padding-left: 10px;
  margin-top: 30px;
  padding: 15px;
}
.Competitor-search-divider{
  font-size: 26px;
  font-weight: 300;
  display: inline;
  margin-right: 10px;
  cursor: pointer;
  color: #7d7575;

}
.Competitor-search-divider2{
  font-size: 34px;
  cursor: default;
}
.Competitor-search-fields2{
  position: absolute;
    bottom: 6px;
    right: 5px;
}

.Competitor-search-fields{
  display: inline-block;
  position: absolute;
  top: 25px;
  margin-left: -56px;
}
.Competitor_reasearch-total3 {
  position: absolute;
  left: 50%;
  top: 120px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.Competitor_reasearch-total2{
  /* position: absolute;
  top: 70px;
  width: 100%;
  background-color:#00263e;
  padding-bottom: 20px;
  padding-left: 10px; */
  position: relative;
  z-index: 4;
    width: 100%;
    background-color: #00263e;
    padding-bottom: 20px;
    padding-left: 10px;
    height: 202px;


}
.Competitor_research-total2-none{
  position: fixed;
  z-index: 4;
    width: 100%;
    background-color: #00263e;
    padding-bottom: 100%;
    padding-left: 10px;
}
.Competitor_reasearch-total{
  position: absolute;
  top: 250px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.Competitor-result-each{
  font-size: 20px;
  padding-left: 15px;
  padding-top: 6px;
  cursor: pointer;

}
.Competitor-result-each:hover{
  background-color: rgb(238, 238, 238);
}
.Competitor-result-each2:hover{
  background-color: rgb(238, 238, 238);
}
.Competitor-result-total{
  border-radius: 2px;
  box-shadow: 0 6px 6px -1px rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 780px;
  background: #fff;
  margin-top: 3px;

}
.Competitor-result-eachpicture{
  display: inline-block;
  width: 72px;
}
#Competitor-brand-total{
  background-color: #0081d2;
  padding: 20px 0 20px 150px;
  min-height: 340px;
  color: #fff;
}
.Competitor-brand-each-name{
  font-size: 30px;
  display: inline-block;
  margin-left: 12px;
  font-weight: 600;

  position: absolute;

}
.Competitor-branch-name{
  margin: 10px 0 3px 0;
  font-size: 17px;
  font-weight: 600;
}
.Competitor-brand-each{
  font-size: 15px;
  color: #fff;
}
.Competitor-result-eachname{
  display: inline-block;
  position: absolute;
  margin-top: 8px;
}
.Competitor-result-image{
  width: 50px;
    height: 40px;
    border-radius: 2px;
    display: inline-block;
}
.Competitor-result-count{
  position: absolute;
  right: 7px;
  margin-top: -41px;
}
.Competitor-result-count-name{
  font-size: 10px;
  position: absolute;
  width: 76px;
  right: -15px;
  margin-top: 26px;
}
.Competitor-research-menu{
  background-color: #00263E;
  width: 100%;
  height: 70px;
  z-index: 9;
  position: fixed;
}
.Competitor-research-menu2{
  position: absolute;
  width: 100%;
}
.Competitor-research-menu-logo{
  width: 180px;
  margin-top: 8px;
  margin-left: 10%;
  display: inline-block;
}
.Competitor-research-menu-logo2{
  width: 220px;
  margin-top: 8px;
  margin-left: 27%;
  display: inline-block;
  margin-bottom: 30px;
}
.Competitor-searchbar-total{
  width: 700px;
}
.Competitor-research-menu-each{
  color: #fff;
  display: inline-block;
    background: rgba(0, 0, 0, 0);
    border: 0;
    border-radius: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: normal;
    outline: none;
    cursor: pointer;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
    width: 90px;
    height: 40px;
}
.Competitor-research-dropdown{
  display: inline-block;
}
.Competitor-menu-tab{
  display: inline-block;
  position: absolute;
}
.Competitor-submenu{
  position: absolute;
  width: 250px;
  background-color: #f1f1f1;
}
.Competitor-submenu-mobile{
  width: 100%;
  background-color: #f1f1f1;
}
.Competitor-menu-tabs{
  display: inline-block;
    position: absolute;
    margin-top: 30px;
    margin-left: 20px;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1) */
}
.Competitor-submenu-each{
  padding: 5px 0 10px 10px;
  cursor: pointer;
}
.Competitor-sidebar-total{
  padding: 12px 0;
  width: 100px;
  color: #a7a7a7;
  position: absolute;
  background: #fff;
  z-index: 1;
  top: 202px;
  text-align: center;
  box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.3);
  
}
.Competitor-box-lock{
  font-size: 70px;
  text-align: center;
  margin-top: -78px;
  color: #034a77;
  cursor: pointer;
  height: 120px;
}
.Competitor-blurred{
  filter: blur(8px);
  cursor: pointer;
}
.Competitor-sidebar-fixed{
  position: fixed;
  top: 70px;
  z-index: 3;
}
.Competitor-sidebar-name{
  padding-bottom: 22px;
  cursor: pointer;
  font-size: 13px;
}
.Competitor-sidebar-image{    
  width: 45px;
  height: 30px;
  margin: 8px 5px 0px 5px;
  cursor: pointer;
}
.Competitor-sidebar-image rect{
  fill:  #a7a7a7;

}
.Competitor-sidebar-image path{
fill:  #a7a7a7;
  stroke: #a7a7a7;
    stroke-width: 8px;
}
.Competitor-sidebar-image2{    
  width: 45px;
  height: 35px;
  margin: 5px 5px 0px 5px;
  cursor: pointer;
}

.Competitor-sidebar-image2 path{
fill:  #158cbf;
  stroke: #158cbf;
    stroke-width: 8px;
}
.Competitor-sidebar-image2 rect{
  fill:  #158cbf;

}
.Competitor-blue{
  color:  #158cbf;

  }
  .Competitor-blue-filter{
    color:  #158cbf;
    padding-bottom: 2px;
    }
    .Competitor-blue-filtered{
      color:  #158cbf;
      padding-bottom: 20px;
      }
.Competitor-paddingleft{
padding-left: 5px;
}
.Competitor-submenu-each:hover{
  background-color: #ddd;
}
.Competitor-research-menu-each:hover{
  color: #396bf0;
}
.Competitor-research-login{
  background-color: #396bf0;
  padding: 7px 20px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
}
.Competitor-research-login2{
  background-color: #00263E;
  padding: 7px 0px;
  display: inline-block;
  width: 103px;
  text-align: center;
  border-radius: 2px;
  margin-left: 184px !important;
  cursor: pointer;
}
.Competitor-register-exit{
  font-size: 38px;
  position: absolute;
  right: 10px;
  top: 2px;
}
.Competitor-brand-topsection{
  display: inline;
}
.Competitor-financials-overall{
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
  padding: 25px 15px 25px 115px;
  
  background: #eff6fe;
}
.Competitor-financials-overall2{
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
  padding: 25px 15px 25px 115px;
  
  background: #eff6fe;
}
.Competitor-financials-box{
  /* grid-template-columns: */
}
.Competitor-socialnone{
  grid-column-start: 1;
  grid-column-end: 4;
}
.Competitor-loading{
  position: fixed;
  background: rgba(0,0,1,.6);
  width: 100%;
  height: 100%;
  z-index: 6;
  text-align: center;
  padding-top: 200px;
  top: 0;
  font-size: 50px;
  color: #fff;
}
.Competitor-box-total{
  width: 100%;
  padding-bottom: 25px;
  min-height: 160px;
  background-color: #fff;
  border-radius: 3px;
  text-align: center;
  box-shadow: 2px 7px 14px 2px rgba(0, 0, 0, 0.2);

}
.Competitor-margintop{
  margin-top:50px;
}
.Competitor-box-value{
  font-size: 40px;
  font-weight: 500;
  color: #034a77;
}
.Competitor-box-title{
  font-size: 13px;
    font-weight: 600;
    margin-top: 20px;
    color: #808080;
}
.Competitor-financials-title{
  grid-column-start: 1;
  grid-column-end: 4;
  font-size: 30px;
  font-weight: 600;
  color: #585858;
}
.Competitor-title-line
{
  border-bottom: 2px solid;
  color: #585858;
  padding-bottom: 10px;
  width: 150px;
}
.Competitor-box-line{
  border-bottom: 1.5px solid #808080;
  width: 40px;
  margin-top: 5px;
  margin-left: 10px;
}
.Competitor-box-title2{
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
  width: 82%;
}
.Competitor-research-topline{
  margin: 15px 0;
  margin-left: 50px;
  width: 400px;
  border-bottom: 1px solid #FFF;
}
.Competitor-research-started{
  background-color: #FF9800!important;
  padding: 7px 20px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 10px;
  cursor: pointer;
}
.color-change{
  background-color: #00263E!important;
  width: 105px;
  padding: 7 0px;
  text-align: center;
}
.color-change2{
  background-color: #FF9800!important;

}
.Competitor-research-started-text{
  margin-left: 100px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.Competitor-research-logintotal{
  position: absolute;
  right: 50px;
  top: 25px;
  color: #FFFFFF;
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.Competitor-computer{
  display: inline;
}
.Competitor-research-hamburger{
  font-size: 32px;
  color: #ddd;
  position: absolute;
  right: 20px;
  top: 23px;
  cursor: pointer;
}
.Competitor-menu-mobile{
  width: 100%;
  height: 100%;
  background-color: #222;
  z-index: 10;
  /* top: 90px; */
  position: fixed;
}
.Competitor-linechart{
  width: 100%;
  max-width: 120px;
  height: 180px;
}
.Competitor-menu-mobile-exit{
  background-color: #ddd;
  color: #222;
  width: 30px;
  height: 30px;
  font-size: 28px;
  border-radius: 20px;
  padding-left: 4px;
  position: absolute;
  right: 30px;
  top: 20px;
}
.Competitor-research-menu-each-mobile{
  font-size: 20px;
  color: #fff;
    cursor: pointer;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
    height: 40px;
    margin-left: 30px;
    margin-top: 15px;
}
.Competitor-research-image{
  /* background-image: url(https://cdn.wallpapersafari.com/88/10/1XJEt4.png); */
  background-image: url(https://mapchise-public-images.s3.amazonaws.com/competitor-research-background);
  width: 100%;
  height: 700px;
  background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    background-color: #00263E;
    z-index: -1;
    box-shadow: inset 2000000px 0 0 0 rgba(0, 38, 62, 0.5)

}
.Competitor-Break60{
  height: 65px;
}
.Competitor-box-branding{
  background-color: #eff6fe;
}
.Competitor-research-imageall{
  background-color: #00263E;
}
.Competitor-search-header{
  text-align: center;
  margin-top: 5%;
  font-weight: 500;
  font-size: 50px;
  color: #fff;
}
.Competitor-search-header2{
  text-align: center;
  font-weight: 400;
  font-size: 35px;
  color: #fff;
}
.Competitor-search-header3{
  font-size: 30px;
  border-bottom: 1px solid;
  width: 400px;
  text-align: center;
  padding-bottom: 25px;
  margin-left: 50px;
}
.wrap-input200{
  border-bottom: none !important;
}
.Competitor-register-total{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  position: fixed;
  overflow: scroll;
}
.Comptitor-popup{
  font-size: 14px;
    font-weight: 600;
}
.mapboxgl-popup-close-button{
  font-size: 26px;
  top: -5;
}
.mapboxgl-marker{
  cursor: pointer;
  
}

.popup-search-results-setup{
  padding-bottom: 12px;
  margin-bottom: 0px;
  cursor: pointer;
  padding-left: 5px;
}

.popup-register-name{
  background: transparent;
  display: block;
  width: 320px;
  padding-bottom: 5px;
  margin-bottom: 25px;
  border-bottom: 1px solid black;
  padding-left: 3px;
  font-size: 15px;
}

.popup-Registration-agreement{
  width: 293px;
  margin: 20px 0px;
  text-align: center;
  font-size: 16px;
}

.popup-register-form-selector{
  font-family: "Source Sans Pro", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  width: 320px;
}

.Competitor-register-name{
  background: transparent;
  width: 320px;
  padding-bottom: 5px;
  margin-bottom: 25px;
  border-bottom: 1px solid #fff;
  color: #fff;
  padding-left: 3px;
  font-size: 20px;
}
.Competitor-register-form{
  padding-left: 80px;
  padding-top: 20px;
}

.Competitor-register-form-selector{
  color: #FFFFFF;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 320px;
}

.Competitor-register-form-selector2{
  color: #FFFFFF;
    font-family: "Source Sans Pro", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 320px;
    margin-bottom: 25px;
}
/* .Competitor-register-form-names{
  font-size: 18px;
  font-weight: 600;
} */
.Competitor-register-main{
  position: absolute;
  background-color: rgb(16 120 185);
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 10px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, .4);
}
.Competitor-register-background{
  width: 500px;
  /* height: 500px; */
  padding-bottom: 20px;

}
.Registration-agreement{
  width: 293px;
  color: #fff;
  margin: 20px 0px;
  text-align: center;
  font-size: 16px;
}
.Registration-agreement a{
  color: #fff;

}
.Competitor-branding-overall{
  display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    height: 400px;
    width: 100%;
    padding: 25px;
}
.Competitor-register-error{
  text-align: center;
  font-family: SourceSansPro;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.02;
  letter-spacing: 1.2px;
  color: red;
  /* position: absolute; */
  /* margin-top: -38px; */
  margin-left: -36px;
  margin-bottom: 20px;
  border: 1px solid #fff;
  width: 400px;
  min-height: 28px;
}
.Competitor-info{
  width: 100%;
  z-index: 5;
  position: absolute;
  background: #e4e4e4;
  box-shadow: 0 7px 14px 0 rgba(60,66,87, 0.12), 0 3px 6px 0 rgba(0,0,0, 0.12);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-top: 26px;
  font-size: 14px;
  font-weight: 600;
  color: #808080;
  padding-top: 10px;
}
.Competitor-info-total{
  position: relative;
}
.Competitor-info-circle{
  position: absolute;
  right: 6px;
  top: 5px;
  cursor: pointer;
}
.Competitor-Break{
  height: 40px;
}
.Competitor-spinner > div {
  width: 23px;
  height: 23px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.Competitor-spinner{
  display: inline;
}
.Competitor-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.Competitor-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.Competitor-branding-box{
  width: 100%;
  height: 370px;
  border-radius: 3px;
  background-color: #eff6fe;
  position: absolute;
  padding: 2px 10px;
}

.Competitor-research-bottom{
  top: 700px;
  width: 100%;
    position: absolute;
    background: #00263e;
    color: #fff;
    background-image: url(https://maptize.com/wp-content/uploads/2020/07/pattern01.png);
}
.Competitor-reasearch-brand{
  display: inline-block;
  width: 140px;
  margin: 20px 5px 10px 5px;
  text-align: center;
}
.Competitor-height38{
  height: 45px;
}
.Competitor-sidebar-name2{
  padding-bottom: 22px;
  cursor: pointer;
  font-size: 18px;

}
.Competitor_reasearch-total-brand {
  position: absolute;
  left: 50%;
  margin-top: 60px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.Competitor_reasearch-brand-total{
  height: 500px;
}
.Competitor-branding-title{
  font-size: 28px;
  color: black;
  padding: 10px;
  font-weight: 600;
  text-align: left;
}
.Competitor-details{
  color: black;
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    list-style-type: circle;
    margin-left: 30px;
    text-align: left;
}
.Competitor-link{
  font-size: 17px;
    /* color: black; */
    font-weight: 600;

}
.Competitor-down{
  margin-top: 60px;
  top: auto;
}
.sidebar-close {
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid #D4D4D4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 3;
  background: #fff;
  right: 450px;
  top: 40%;
  font-size: 24px;
  padding-top: 10px;
  padding-left: 7px;
}
.Competitor-branding-overall-branding{
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  min-height: 400px;
  width: 100%;
  padding: 25px;
}
.Competitor-research-loginsize{
  padding: 15px 20px;
}
.Competitor-link-total{
  text-align: left;
    padding-left: 10px;
    font-size: 14px;
}
.Competitor-details-total{
  width: 380px;
}
.Competitor-branding-image{
  width: 300px;
    position: absolute;
    right: 2px;
    top: 8px;
}
.Competitor-footer{
  height: 120px;
  background-color: transparent;
    background-image: linear-gradient(180deg, #1B1B1B 0%, #393939 100%);
}
.Competitor-footer-text{
  position: absolute;
  left: 80px;
    margin-top: 10px;
}
.Competitor-footer-each{
  display: inline-block;
  margin: 0 10px;
}
.Competitor-foooter-links{
  position: absolute;
    width: 100%;
    text-align: right;
}
.Competitor-contact-total{
  background-color: #00263E;
    background-image: url(https://maptize.com/wp-content/uploads/2020/07/pattern01.png);
  height: 300px;
  }
.search-results-values-sidebar{
  left: 0px;
  top: 28px;
}
  .Competitor-margintop100{
    margin-top: 100px;
  }
  .dashboard-sidebargrid{
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 20px 7px;
    margin-right: 10px;
  }
  .dashboard-sidebargrid2{
    grid-template-columns: 1fr;
    display: grid;
    grid-gap: 20px 7px;
    margin-top: 20px;
    margin-right: 10px;
  }
  .dashboard-sidebarimage{
    width: 120px;
    height: 120px;
    margin-left: 195px;
    margin-bottom: 22px;
    margin-top: 10px;
  }
  .dashboard-limited{
    position: absolute;
    margin-left: 17px;
  }
  .dashboard-location-total{
    margin-left: 20px;
    margin-top: 150px;
    position: absolute;
    width: 90%;
    z-index: 3;
    padding-left: 70px;
    padding-top: 20px;
  }
  .dashboard-sidebarimage-name{
    position: absolute;
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    width: 221px;
    margin-top: 37px;
    color: #034a77;
  }
  .dashboard-sidebarimage-total{
    padding-bottom: 25px;
    min-height: 160px;
    background-color: #fff;
    border-radius: 3px;
    text-align: center;
    box-shadow: 2px 7px 14px 2px rgba(0, 0, 0, 0.2);
    margin-left: 15px;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .dashboard-Image{
    position: absolute;
    z-index: 3;
    width: 230px;
    margin-top: 7px;
    cursor: pointer;
  }
  .dashboard-Image-total{
    position: absolute;
    z-index: 3;
    margin-left: 15px;
  }
  .dashboard-Image-name{
    position: absolute;
    font-size: 24px;
    color: #00263e;
    font-weight: bold;
    width: 200px;
    margin-left: 80px;
  }
  .margin-top30{
    margin-top: 30px;
  }
  .dashboard-locked{
    font-size: 20px;
    margin-left: 25px;
    color: #a7a7a7;
    margin-top: 5px;
  }
  .dashboard-locked-top{
    font-size: 20px;
    margin-left: 35px;
    color: #a7a7a7;
    position: absolute;
    display: block;
  }
  .height60{
    height: 60px;
  }
  .Layer-sidebar-info{
    position: absolute;
    left: 70px;
    width: 200px;
    margin-top: -30px;
  }
  .dashboard-locked2{
    font-size: 15px;
    margin-left: -30px;
    color: #a7a7a7;
    margin-top: 11px;
    position: absolute;
  }
  .dashboard-blurred{
    filter: blur(8px);
  -webkit-filter: blur(8px);
  }
  .dasbhoard-Image-name{
    position: absolute;
    font-size: 24px;
    color: #00263e;
    font-weight: bold;
    width: 200px;
    margin-left: 80px;
  }
  .Initial_Questions_each{
    padding-bottom: 12px;
    font-weight: 400;
    font-size: 17px;
    color: black;
    width: 320px;
  }
  .Initial_Questions_Input_textarea{
    background: transparent;
    width: 320px;
    padding-bottom: 5px;
    margin-bottom: 25px;
    border: 1px solid lightgray;
    color: black;
    padding-left: 3px;
    font-size: 15px;
  }
  .Initial_popup_survey_mainText{
    margin-top: 40px;
    font-weight: 400;
    font-size: 22px;
    color: black;
    border-bottom: 1px solid;
    padding-bottom: 20px;
    text-align: center;
    margin-bottom: 15px;
  }
  .popup-main-color{
    background-color: #fdfdfd;
  }
  @media only screen and (min-width: 1500px){
    .Competitor-branding-overall-branding{
      padding: 25px 250px;
    }
  }
@media only screen and (max-width: 800px){
  .sidebar-total{
    right: auto;
    width: auto;
    z-index: 6;
  }
  .login-logo-fremium-total{
    left: 350px !important;
  }
  .sidebar-total-dashboard{
    
    width: 100%;
    
  }
  .sidebar-total-hide{
    right: 0px;
  }
  .Competitor-footer{
    height: 160px;
  }
  .Competitor-foooter-links{
    margin-top: 50px;
  }
.Competitor-financials-overall2{
  grid-template-columns: 1fr;
  padding: 3px 5px 25px 5px;

}
.Competitor-branding-overall-branding{
  grid-template-columns: 1fr;

}
.Competitor_research-input{
  width: 350px;
}
.Competitor-result-total{
  width: 350px;
}
#Competitor-brand-total{
  padding: 51px 0px 20px 10px;
}
.Competitor-financials-overall{
  padding: 3px 5px 25px 5px;
}
.Competitor-box-value {
font-size: 30px;
}

.Competitor-social-image{
  width: 20px;
}
.Competitor-brand-each-name{
  margin-top: -27px;
  margin-left: 53px;

}
.Competitor-social-total{
  display: block;
  z-index: 1;
  /* margin-left: 38px; */
  margin: 0;
}
.Competitor-result-image{
  width: 68px;
  height: 48px;
}
.Competitor_research-input2{
  width: 100%;
}
.Competitor-search-header{
  font-size: 32px;
}
.Competitor-search-header2{
  font-size: 25px;
}
.Competitor_reasearch-total-brand{
  transform: none !important;
    margin: 0 !important;
    position: initial !important;
}
.Competitor-height38 {
  height: 38px;
}
.Competitor-sidebar-name2{
  font-size: 11px !important;
  font-weight: 600;
}
.Competitor-reasearch-brand{
  width: 65px !important;
}
.Competitor-branding-image{
  width: 136px;
  right: 2px;
  bottom: 0px;
  top: auto;
}
.Competitor-details-total{
  width: auto;
}
}

